import React ,{ useState, useRef, useEffect }from 'react'
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';

const EducationDurationDropdown = ({ options, selectedOption, onSelect, toggleDropdown, durationOpen, setDurationOpen }) => {
  const handleSelect = (option) => {
    onSelect(option);
    setDurationOpen(false);
  };
  const dropdownRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDurationOpen(false); // Close dropdown if click is outside
      }
    };

    if (durationOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    // Cleanup listener on component unmount or dropdown close
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [durationOpen]);

  return (
    <div>
      <div className="relative" ref={dropdownRef}>
        <div className="relative inline-block w-full">
          <div
            className="border border-gray-300  w-full md:w-[200px]   text-[12px] py-2 px-4 text-gray-700 leading-tight flex justify-between items-center outline-none"
            onClick={toggleDropdown}
          >
           <div className={`${!selectedOption ? 'text-gray-400' : 'text-black'}`}>
              {selectedOption ? selectedOption : 'Select Option'}
            </div>
            <div> {durationOpen ? <FiChevronUp /> : <FiChevronDown />}</div>

          </div>
          {durationOpen && (
            <ul className="absolute z-10 mt-1 w-full bg-white border border-gray-400 rounded-md shadow-lg">
              {options.map((option, index) => (
                <li
                  key={index}
                  className="px-4 py-2 cursor-pointer hover:bg-black hover:text-white text-sm"
                  onClick={() => handleSelect(option)}
                >
                  <div className='text-[12px]'>{option}</div>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>







    </div>
  )
}

export default EducationDurationDropdown