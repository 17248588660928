import React, { useState } from 'react';
import { motion } from 'framer-motion';
import axiosInstance from '../axiosInstance/axiosInstance';

const SourceUpdateForm = ({ setGetSource, getSource, setUpdateSource, updateSource,fetchSources }) => {
  const [updatedSourceName, setUpdatedSourceName] = useState(getSource.name); 

  const handleClose = () => {
    setUpdateSource(false);
  };

  const handleSubmit = async () => {
    try {
      const response = await axiosInstance.put(`/kanban/api/v1/source_detail_update/${getSource.id}/`, {
        name: updatedSourceName,
      });
      console.log('Updated:', response.data); 
      fetchSources()

      
      setUpdateSource(false); 
      setGetSource(null); 
    } catch (error) {
      console.error('Error updating source:', error);
    }
  };

  return (
    <div className='fixed inset-0 z-50 rounded-md h-[100vh] overflow-y-auto'>
      {updateSource && (
        <>
          <motion.div
            className="fixed inset-0 bg-black bg-opacity-70"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          />
          <motion.div
            className="p-6 z-10 fixed inset-0 flex justify-center items-center"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <div className="w-[500px] bg-white shadow-md">
              <div className="bg-black flex items-center justify-center">
                <span className="text-2xl font-bold px-6 py-4 text-white">
                  Update Source
                </span>
              </div>
              <form className="px-6 pt-6 pb-8">
                <div className="mb-4">
                  <label
                    className="block text-gray-700 text-[12px] text-sm font-bold mb-2"
                    htmlFor="sourceName"
                  >
                    Update Source Here
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight text-[12px] focus:outline-none focus:shadow-outline"
                    id="sourceName"
                    type="text"
                    placeholder="Enter Source"
                    value={updatedSourceName}
                    onChange={(e) => setUpdatedSourceName(e.target.value)} // Update state on change
                  />
                </div>
                <div className="flex justify-end pt-10 gap-2">
                  <button
                    className="bg-black pt-sans-regular text-[14px]  text-white font-bold py-1 px-4 rounded focus:outline-none focus:shadow-outline"
                    type="button"
                    onClick={handleSubmit} 
                  >
                    Submit
                  </button>
                  <button
                    className="bg-black pt-sans-regular text-[14px]  text-white font-bold py-1 px-4 rounded focus:outline-none focus:shadow-outline"
                    type="button"
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </motion.div>
        </>
      )}
    </div>
  );
};

export default SourceUpdateForm;
