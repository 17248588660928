import React, { useState, useEffect, useRef } from 'react';
import axiosInstance from '../axiosInstance/axiosInstance';
import { motion } from 'framer-motion';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import AddIcon from '@mui/icons-material/Add';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const UpdateCountry = ({ countryId, onClose, setIsUpdateCountryVisible ,fetchCountryData }) => {
    const [name, setName] = useState('');
    const [rank, setRank] = useState('');
    const [flagUrl, setFlagUrl] = useState('');
    const [countryUrl, setCountryUrl] = useState('');
    const [bannerImageUrl, setBannerImageUrl] = useState('');
    const [inputs, setInputs] = useState([{ id: 1, value: '' }]);
    const [showError, setShowError] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [flagFileName, setFlagFileName] = useState('No file chosen');
    const [countryFileName, setCountryFileName] = useState('No file chosen');
    const [bannerFileName, setBannerFileName] = useState('No file chosen');
    const [isActive, setIsActive] = useState(true); // Track is_active status

    const [whatsappUrl, setWhatsappUrl] = useState('')
    const flagImageInputRef = useRef(null);
    const countryImageInputRef = useRef(null);
    const bannerImageInputRef = useRef(null);
    const [rightPosition, setRightPosition] = useState('right-[10px]');
    const [topPosition, setTopPosition] = useState('top-[10px]');
    useEffect(() => {
        const path = window.location.pathname;
        if (path === '/countrydashboard') {
            setRightPosition('right-[60px]');
            setTopPosition('top-[-70px]');
        } else if (path === '/university') {
            setRightPosition('right-[10px]');
            setTopPosition('top-[10px]');
        } else {
            // Default positioning for other routes if needed
            setRightPosition('right-[10px]');
            setTopPosition('top-[10px]');
        }
    }, [window.location.pathname]);

    useEffect(() => {
        // Fetch country details from API
        axiosInstance.get(`/kanban/api/v1/country_detail/${countryId}/`)
            .then(response => {
                const countryData = response.data;
                setWhatsappUrl(countryData.whatsapp_url)

                setName(countryData.name);
                setRank(countryData.rank);
                setFlagUrl(countryData.flag || '');
                setCountryUrl(countryData.image || '');
                setBannerImageUrl(countryData.banner_image || '');
                setIsActive(countryData.is_active);


                const uspNames = countryData.usps.map(usp => {
                    try {

                        const parsedValue = JSON.parse(usp.name);

                        return Array.isArray(parsedValue) ? parsedValue : [usp.name];
                    } catch {

                        return [usp.name];
                    }
                }).flat();


                setInputs(uspNames.length ? uspNames.map((name, index) => ({ id: index + 1, value: name })) : [{ id: 1, value: '' }]);


            })
            .catch(error => {
                console.error("Error fetching country details:", error);
                setShowError(true);
            });
    }, [countryId]);


    const handleInputChange = (id, event) => {
        setInputs(inputs.map(input =>
            input.id === id ? { ...input, value: event.target.value } : input
        ));
    };


    const addInputField = () => {
        setInputs([...inputs, { id: inputs.length + 1, value: '' }]);
    };

    const removeInputField = (id) => {
        setInputs(inputs.filter(input => input.id !== id));
    };

    const handleImageButtonClick = (inputRef) => {
        inputRef.current.click();
    };


    const uploadImage = async (file, setFileName, setImageUrl) => {
        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await axiosInstance.post('/home/api/v1/upload-file/', formData);
            const imageUrl = response.data.image_url; // Adjust based on the actual response format
            setFileName(file.name);
            setImageUrl(imageUrl);
        } catch (error) {
            console.error('Error uploading image:', error);
        }
    };



    const handleImageChange = (setFileName, setImageUrl) => (event) => {
        const file = event.target.files[0];
        if (file) {
            uploadImage(file, setFileName, setImageUrl);
        }
    };

    const handleBannerImageUrlChange = (event) => {
        setBannerImageUrl(event.target.value);
    };




    const handleSubmit = async () => {
        const updatedData = {
            name,
            whatsapp_url: whatsappUrl,
            rank,
            flag: flagUrl,
            image: countryUrl,
            banner_image: bannerImageUrl,
            usps: inputs.map(input => input.value),
        };

        try {
            const response = await axiosInstance.put(`/kanban/api/v1/country_detail/${countryId}/`, updatedData);

            if (response.status === 200) {
                setShowAlert(true);
                setTimeout(() => setIsUpdateCountryVisible(false), 2000);
            }
        } catch (error) {
            console.error("Error updating country details:", error);
            setShowError(true);
        }
    };


    const handleClose = () => {
        setIsUpdateCountryVisible(false);
    };

    const toggleVisibility = async () => {
        const updatedIsActive = !isActive;

        try {
            const response = await axiosInstance.put(`/kanban/api/v1/country_detail/${countryId}/`, {
                is_active: updatedIsActive
            });

            if (response.status === 200) {
                fetchCountryData()
                setIsActive(updatedIsActive); // Update state based on response
            }
        } catch (error) {
            console.error("Error updating country visibility:", error);
        }
    };
    return (
        <div>
            <motion.div
                className="fixed inset-0 bg-black bg-opacity-70"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                onClick={onClose}
            />
            <motion.div
                className="p-6 z-10"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
            >
                <div className={`absolute z-10 ${topPosition} ${rightPosition} w-full flex justify-center`}>
                    <div className="shadow-xl w-[700px] bg-white rounded-md">
                        <div className='bg-black flex items-center justify-center'>
                            <span className="text-2xl font-bold px-6 py-6 text-white">
                                Update Country
                            </span>
                        </div>
                        <div className='px-4 py-4 pt-8'>
                            <div className='flex justify-between mb-4'>
                                <div>
                                    <label
                                        htmlFor="country-input"
                                        className="block text-gray-700 text-[12px] text-gray-600 mb-2 inter-semilight"
                                    >
                                        Country Name
                                    </label>
                                    <input
                                        id="country-input"
                                        type="text"
                                        className="border border-gray-300 rounded-md w-[320px] text-[12px] py-2 px-4"
                                        placeholder="Enter text"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </div>
                                <div>
                                    <label
                                        htmlFor="rank-input"
                                        className="block text-gray-700 text-[12px] text-gray-600 mb-2 inter-semilight"
                                    >
                                        Rank
                                    </label>
                                    <input
                                        id="rank-input"
                                        type="text"
                                        className="border border-gray-300 rounded-md w-[320px] text-[12px] py-2 px-4"
                                        placeholder="Enter text"
                                        value={rank}
                                        onChange={(e) => setRank(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className='flex justify-between mb-4'>
                                <div>
                                    <label
                                        htmlFor="flag-url-input"
                                        className="block text-gray-700 text-[12px] text-gray-600 mb-2 inter-semilight"
                                    >
                                        You can Add Flag URL
                                    </label>
                                    <input
                                        id="flag-url-input"
                                        type="text"
                                        className="border border-gray-300 rounded-md mb-4 w-[320px] text-[12px] py-2 px-4"
                                        placeholder="Enter text"
                                        value={flagUrl}
                                        onChange={(e) => setFlagUrl(e.target.value)}
                                    />
                                </div>
                                <div>
                                    <label
                                        htmlFor="flag-image-upload"
                                        className="block text-gray-700 text-[12px] text-gray-600 mb-2 inter-semilight"
                                    >
                                        OR Drop Flag image here
                                    </label>
                                    <div className='bg-black w-[320px] border-dotted p-1.5 rounded-md' onClick={() => handleImageButtonClick(flagImageInputRef)}>
                                        <div className='flex items-center justify-center'>
                                            {flagFileName === 'No file chosen' ? (
                                                <div className='flex items-center gap-2'>

                                                    <button type="button" className="rounded-md text-[12px] text-white">
                                                        Drag and Drop Flag Image
                                                    </button>
                                                </div>
                                            ) : (
                                                <div className='flex items-center gap-2'>

                                                    <div className=' text-[12px] text-white'>
                                                        {flagFileName}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <input
                                            id="flag-image-upload"
                                            type="file"
                                            ref={flagImageInputRef}
                                            onChange={handleImageChange(setFlagFileName, setFlagUrl)}
                                            style={{ display: 'none' }}
                                        />
                                    </div>
                                    <div className="text-gray-500 text-[12px]">
                                        File size should not exceed 1MB.
                                    </div>
                                </div>
                            </div>
                            <div className='flex justify-between mb-4'>
                                <div>
                                    <label
                                        htmlFor="country-url-input"
                                        className="block text-gray-700 text-[12px] text-gray-600 mb-2 inter-semilight"
                                    >
                                        You can Add Country URL
                                    </label>
                                    <input
                                        id="country-url-input"
                                        type="text"
                                        className="border border-gray-300 rounded-md mb-4 w-[320px] text-[12px] py-2 px-4"
                                        placeholder="Enter text"
                                        value={countryUrl}
                                        onChange={(e) => setCountryUrl(e.target.value)}
                                    />
                                </div>
                                <div>
                                    <label
                                        htmlFor="country-image-upload"
                                        className="block text-gray-700 text-[12px] text-gray-600 mb-2 inter-semilight"
                                    >
                                        OR Drop Country image here
                                    </label>
                                    <div className='bg-black w-[320px]  p-1.5 rounded-md' onClick={() => handleImageButtonClick(countryImageInputRef)}>
                                        <div className='flex items-center justify-center'>
                                            {countryFileName === 'No file chosen' ? (
                                                <div className='flex items-center gap-2'>

                                                    <button type="button" className="rounded-md text-[12px] text-white">
                                                        Drag and Drop Country Image
                                                    </button>
                                                </div>
                                            ) : (
                                                <div className='flex items-center gap-2'>

                                                    <div className=' text-[12px] text-white'>
                                                        {countryFileName}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <input
                                            id="country-image-upload"
                                            type="file"
                                            ref={countryImageInputRef}
                                            onChange={handleImageChange(setCountryFileName, setCountryUrl)}
                                            style={{ display: 'none' }}
                                        />
                                    </div>
                                    <div className="text-gray-500 text-[12px]">
                                        File size should not exceed 1MB.
                                    </div>
                                </div>
                            </div>
                            <div className='flex justify-between mb-4'>
                                <div>
                                    <label
                                        htmlFor="banner-image-url-input"
                                        className="block text-gray-700 text-[12px] text-gray-600 mb-2 inter-semilight"
                                    >
                                        Banner Image URL
                                    </label>
                                    <input
                                        id="banner-image-url-input"
                                        type="text"
                                        className="border border-gray-300 rounded-md mb-4 w-[320px] text-[12px] py-2 px-4"
                                        placeholder="Enter text"
                                        value={bannerImageUrl}
                                        onChange={handleBannerImageUrlChange}
                                    />
                                </div>
                                <div>
                                    <label
                                        htmlFor="banner-image-upload"
                                        className="block text-gray-700 text-[12px] text-gray-600 mb-2 inter-semilight"
                                    >
                                        OR Drop Banner image here
                                    </label>
                                    <div className=' w-[320px] bg-black border-dotted p-1.5 rounded-md' onClick={() => handleImageButtonClick(bannerImageInputRef)}>
                                        <div className='flex items-center justify-center'>
                                            {bannerFileName === 'No file chosen' ? (
                                                <div className='flex items-center gap-2'>

                                                    <button type="button" className="rounded-md text-[12px] text-white">
                                                        Drag and Drop Banner Image
                                                    </button>
                                                </div>
                                            ) : (
                                                <div className='flex items-center gap-2'>

                                                    <div className=' text-[12px] text-white'>
                                                        {bannerFileName}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <input
                                            id="banner-image-upload"
                                            type="file"
                                            ref={bannerImageInputRef}
                                            onChange={handleImageChange(setBannerFileName, setBannerImageUrl)}
                                            style={{ display: 'none' }}
                                        />
                                    </div>
                                    <div className="text-gray-500 text-[12px]">
                                        File size should not exceed 1MB.
                                    </div>
                                </div>

                            </div>
                            <div className='flex justify-between items-center '>
                                <div className='mb-4'>
                                    <label
                                        htmlFor="country-input"
                                        className="block text-gray-700 text-[12px] text-gray-600 mb-2 inter-semilight"
                                    >
                                        Whatsapp URL
                                    </label>
                                    <input
                                        id="country-input"
                                        type="text"
                                        className="border border-gray-300 rounded-md w-[320px] text-[12px] py-2 px-4"
                                        placeholder="Enter text"
                                        value={whatsappUrl}
                                        onChange={(e) => setWhatsappUrl(e.target.value)}
                                    />
                                </div>
                                <div className='  w-[320px] h- border  bg-black border-gray-7000  py-1 rounded-md'>

                                    <div className='flex items-center justify-center cursor-pointer' onClick={toggleVisibility}>
                                        {isActive ? (
                                            <div >
                                                <VisibilityIcon style={{ fontSize: '20px' }} className="cursor-pointer text-white" />
                                                <span className='text-white text-[12px] px-4'>Show Country</span>
                                            </div>
                                        ) : (
                                            <div>
                                                <VisibilityOffIcon style={{ fontSize: '20px' }} className="cursor-pointer text-white" />
                                                <span className='text-white text-[12px] px-4'>Hide Country</span>
                                            </div>
                                        )}
                                    </div>



                                </div>

                            </div>


                            <div className='mb-4'>
                                <label
                                    htmlFor="usp-input"
                                    className="block text-gray-700 text-[12px] text-gray-600 mb-2 inter-semilight"
                                >
                                    USPs
                                </label>
                                <div className='flex flex-wrap gap-4 '>
                                    {inputs.map(input => (

                                        <div key={input.id} className='flex mb-2 items-center'>
                                            <input
                                                type="text"
                                                className="border border-gray-300 rounded-md w-[320px] text-[12px] py-2 px-4"
                                                value={input.value}
                                                onChange={(e) => handleInputChange(input.id, e)}
                                            />
                                            <DeleteIcon
                                                className='cursor-pointer ml-2'
                                                style={{ fontSize: '18px', color: 'red' }}
                                                onClick={() => removeInputField(input.id)}
                                            />

                                        </div>
                                    ))}
                                </div>

                                <button
                                    type="button"
                                    onClick={addInputField}
                                    className="flex items-center gap-2 text-black"
                                >
                                    <AddIcon style={{ fontSize: '18px' }} />

                                </button>
                            </div>




                            {showAlert && (
                                <Stack sx={{ width: '100%' }} spacing={2}>
                                    <Alert severity="success">Country updated successfully!</Alert>
                                </Stack>
                            )}
                            {showError && (
                                <Stack sx={{ width: '100%' }} spacing={2}>
                                    <Alert severity="error">There was an error updating the country.</Alert>
                                </Stack>
                            )}
                            <div className='flex justify-end gap-2'>
                                <button
                                    type="button"
                                    className="bg-black text-white font-bold py-1 px-8 text-[13px] rounded-sm inter-light"
                                    onClick={handleSubmit}
                                >
                                    Update
                                </button>
                                <button
                                    type="button"
                                    className="bg-black text-white font-bold py-1 px-8 text-[13px] rounded-sm inter-light"
                                    onClick={handleClose}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </motion.div>

        </div>
    );
};

export default UpdateCountry;















