import React, { useState, useEffect } from 'react'
import SideBar from '../SideBar/SideBar'
import UniversityHeader from './UniversityHeader'
import UniversiySearchBar from './UniversiySearchBar'
import UniversityCenter from './UniversityCenter'
import axiosInstance from '../axiosInstance/axiosInstance'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
const UniversityCenterDashboard = () => {
    const [isAddCountryVisible, setIsAddCountryVisible] = useState(false);
    const [isUpdateCountryVisible, setIsUpdateCountryVisible] = useState(false);
    const [isAddUniversityVisible, setIsAddUniversityVisible] = useState(false);
    const [isUpdateUniversityVisible, setIsUpdateUniversityVisible] = useState(false);
    const [universities, setUniversities] = useState([]);
    const [nextPage, setNextPage] = useState(null);
    const [prevPage, setPrevPage] = useState(null);
    const [searchCountry, setSearchCountry] = useState('');
    const [searchUniversity, setSearchUniversity] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1); 
    const itemsPerPage = 10;
    const [totalEntries, setTotalEntries] = useState(0);
    const [showUnassigned, setShowUnassigned] = useState(false);
    const [isUnassignedView, setIsUnassignedView] = useState(true); 
    const[universityCount,setUniversityCount]=useState('')
    const[unassignedCount,setUnassignedCount]=useState('')
    const [loading, setLoading] = useState(true);
    const toggleAddCountryModal = () => {
        setIsAddCountryVisible(prev => !prev);
    };
    const toggleAddUniversityModal = () => {
        setIsAddUniversityVisible(prev => !prev);
    };
    // const fetchUniversities = (url = 'home/api/v1/universities/?limit=4') => {
    //     axiosInstance.get(url, {
    //         params: {
    //             country: searchCountry,
    //             search_university: searchUniversity,
    //         },
    //     })
    //         .then(response => {
    //             setUniversities(response.data.results);
    //           // Enable Unassigned View
    //             setUniversityCount(response.data.count)
    //             setNextPage(response.data.next);
    //             setPrevPage(response.data.previous);
    //             const totalCount = response.data.count; // Get the total count of universities
    //             setTotalPages(Math.ceil(totalCount / itemsPerPage));
    //             setTotalEntries(totalCount);
    //         })
    //         .catch(error => {
    //             console.error("There was an error fetching the university data!", error);
    //         });
    // };
    const fetchUniversities = (url = 'home/api/v1/universities/', country = '', search_university = '', offset = 0) => {
        
        axiosInstance.get(url, {
            params: {
                country,
                search_university,
                limit: itemsPerPage,
                offset,
            },
        })
            .then(response => {
                setUniversities(response.data.results);
                setUniversityCount(response.data.count);
                setNextPage(response.data.next);
                setPrevPage(response.data.previous);
                const totalCount = response.data.count;
                setTotalPages(Math.ceil(totalCount / itemsPerPage));
                setTotalEntries(totalCount);
            })
            .catch(error => {
                console.error("There was an error fetching the university data!", error);
            });
    };
    


    useEffect(() => {
        fetchUniversities('home/api/v1/universities/?limit=10', searchCountry, searchUniversity);
    }, [searchCountry, searchUniversity]);

    
    // const handleNext = () => {
    //     if (nextPage) {
    //         fetchUniversities(nextPage);
    //         setCurrentPage((prev) => prev + 1);
    //     }
    // };

    // // Handle "Previous" button click
    // const handlePrevious = () => {
    //     if (prevPage) {
    //         fetchUniversities(prevPage);
    //         setCurrentPage((prev) => prev - 1);
    //     }
    // };
    const handleNext = () => {
        if (nextPage) {
            const offset = currentPage * itemsPerPage;
            fetchUniversities('home/api/v1/universities/', searchCountry, searchUniversity, offset);
            setCurrentPage(prev => prev + 1);
        }
    };
    
    const handlePrevious = () => {
        if (prevPage) {
            const offset = (currentPage - 2) * itemsPerPage;
            fetchUniversities('home/api/v1/universities/', searchCountry, searchUniversity, offset);
            setCurrentPage(prev => prev - 1);
        }
    };
    
    const startIndex = (currentPage - 1) * itemsPerPage + 1;
    const endIndex = Math.min(currentPage * itemsPerPage, totalEntries);

    const fetchUnassignedUniversities = () => {
     
        axiosInstance.get('home/api/v1/unassigned-univeristy-list/?limit=10')
        .then(response => {
            setUniversities(response.data.results);
            setUnassignedCount(response.data.count)
         
            
            setNextPage(response.data.next);
            setPrevPage(response.data.previous);
            const totalCount = response.data.count;
            setTotalPages(Math.ceil(totalCount / itemsPerPage));
            setTotalEntries(totalCount);
        })
        .catch(error => {
            console.error("There was an error fetching the unassigned universities!", error);
        });
    };
      useEffect(() => {
        fetchUnassignedUniversities()
    
        }, [])
    




    return (
        <div>
            <div className='flex bg-gray-50 h-full'>
                <div className='pt-8 bg-white h-screen sticky   top-0 '>
                    <SideBar />
                </div>
                <div className='flex flex-col px-4     w-full '>
                    <div className=' py-6'>
                    <UniversityHeader
                    unassignedCount={unassignedCount}
                    universityCount={universityCount}
                            fetchUnassignedUniversities={fetchUnassignedUniversities}
                            fetchUniversities={fetchUniversities}
                            isUnassignedView={isUnassignedView} // Pass the state to header
                            setIsUnassignedView={setIsUnassignedView} // To reset view
                        />

                    </div>

                    <div className='shadow-md bg-white flex flex-col gap-4 pt-4 pb-4 px-4 h-full'>
                        <UniversiySearchBar toggleAddCountryModal={toggleAddCountryModal} toggleAddUniversityModal={toggleAddUniversityModal} onSearchCountry={setSearchCountry}
                            onSearchUniversity={setSearchUniversity} searchUniversity={searchUniversity} searchCountry={searchCountry} />
                        <UniversityCenter isAddCountryVisible={isAddCountryVisible} setIsAddCountryVisible={setIsAddCountryVisible} setIsAddUniversityVisible={setIsAddUniversityVisible} isAddUniversityVisible={isAddUniversityVisible} universities={universities} setUniversities={setUniversities} fetchUniversities={fetchUniversities} prevPage={prevPage} nextPage={nextPage} isUpdateCountryVisible={isUpdateCountryVisible} setIsUpdateCountryVisible={setIsUpdateCountryVisible} isUpdateUniversityVisible={isUpdateUniversityVisible} setIsUpdateUniversityVisible={setIsUpdateUniversityVisible}currentPage={currentPage} itemsPerPage={itemsPerPage} />

                    </div>

                    <div className="flex gap-2 py-6 items-center  justify-between ">
                        <div className="text-[11px]  px-4 py-0.5 text-gray-500 pt-sans-bold">
                        {`Showing ${totalEntries > 0 ? 1 : 0} to ${totalPages} of ${totalEntries} entries`}
                        </div>
                        <div className='flex gap-1'>
                            <div className='flex gap-1'>
                                <div>
                                    <button
                                        className=" border border-gray-600  text-gray-700  rounded-sm pt-sans-bold"
                                        onClick={handlePrevious}
                                    >
                                        <KeyboardDoubleArrowLeftIcon style={{ fontSize: '20px' }} />
                                    </button>

                                </div>
                                <div>

                                    <button
                                        className=" border border-gray-600  text-gray-700  rounded-sm pt-sans-bold"
                                        onClick={handlePrevious}
                                    >
                                        <KeyboardArrowLeftIcon style={{ fontSize: '20px' }} />
                                    </button>

                                </div>



                            </div>

                            <div className=" text-[14px] bg-black px-4 py-0.5 text-white pt-sans-bold">{currentPage}</div>
                            {nextPage && (
                                <div>
                                    <button
                                        className="border border-gray-600 text-gray-700 rounded-sm pt-sans-bold"
                                        onClick={handleNext}
                                    >
                                        < KeyboardArrowRightIcon style={{ fontSize: '20px' }} />

                                    </button>
                                </div>
                            )}
                            {nextPage && (
                                <div>
                                    <button
                                        className="border border-gray-600 text-gray-700 rounded-sm pt-sans-bold"
                                        onClick={handleNext}
                                    >
                                        <KeyboardDoubleArrowRightIcon style={{ fontSize: '20px' }} />
                                    </button>
                                </div>
                            )}

                        </div>


                        <div className='flex justify-end items-end'>
                            <div className="text-[12px] flex px-0.5 border border-1 border-gray-300 py-0.5 text-gray-500 pt-sans-bold">
                                <div>
                                    {`${totalPages}`}

                                </div>
                                <div>
                                    <KeyboardArrowDownIcon style={{ fontSize: '18px' }} />

                                </div>


                            </div>

                        </div>





                    </div>



                </div>


            </div>





        </div>
    )
}

export default UniversityCenterDashboard

