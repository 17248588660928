
import React, { useRef, useEffect, useState } from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';

const LanguageRequirementDrop = ({ options, onSelect, toggleDropdown, languageOpen, setLanguageOpen, setErrors, initialData }) => {
  const [inputValues, setInputValues] = useState({});
  const [checkedOptions, setCheckedOptions] = useState({});
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    if (initialData && Array.isArray(initialData)) {
      const newCheckedOptions = {};
      const newInputValues = {};

      initialData.forEach(req => {
        newCheckedOptions[req.language_requirement] = true;
        newInputValues[req.language_requirement] = req.score;
      });

      setCheckedOptions(newCheckedOptions);
      setInputValues(newInputValues);
      setSelectedOptions(initialData.map(req => req.language_requirement));
    }
  }, [initialData]); // Re-run this effect when initialData changes

  const updateSelectedRequirements = (updatedCheckedOptions, updatedInputValues) => {
    const selectedRequirements = Object.keys(updatedCheckedOptions).map((key) => {
      if (updatedCheckedOptions[key]) {
        return { language_requirement: key, score: key === 'MOI' ? null : updatedInputValues[key] || '' };
      }
      return null;
    }).filter(item => item !== null);

    setSelectedOptions(selectedRequirements.map(req => req.language_requirement));
    onSelect(selectedRequirements);
  };


  const handleCheckboxChange = (option) => {
    const updatedCheckedOptions = {
      ...checkedOptions,
      [option.name]: !checkedOptions[option.name],
    };
    setCheckedOptions(updatedCheckedOptions);
    updateSelectedRequirements(updatedCheckedOptions, inputValues);
  };
  const handleInputChange = (option, event) => {
    if (option.name === 'MOI') return; 

    const updatedInputValues = {
      ...inputValues,
      [option.name]: event.target.value,
    };
    setInputValues(updatedInputValues);
    updateSelectedRequirements(checkedOptions, updatedInputValues);
  };

  const dropdownRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setLanguageOpen(false);
      }
    };

    if (languageOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [languageOpen]);

  return (
    <div>
      <div className="relative" ref={dropdownRef}>
        <div className="relative inline-block">
          <div
            className="border border-gray-300 w-full md:w-[200px] text-[12px] py-2 px-4 text-gray-700 leading-tight flex justify-between items-center outline-none"
            onClick={toggleDropdown}
          >
            <div className={`${selectedOptions.length === 0 ? 'text-gray-400' : 'text-black'}`}>
              {selectedOptions.length > 0 ? selectedOptions.join(', ') : 'Select Option'}
            </div>
            <div>{languageOpen ? <FiChevronUp /> : <FiChevronDown />}</div>
          </div>

          {languageOpen && (
            <ul className="absolute z-10 mt-1 w-full bg-white border border-gray-400 rounded-md shadow-lg">
              {options.map((option, index) => (
                <li key={index} className="px-4 py-2 flex items-center cursor-pointer hover:bg-black hover:text-white text-sm">
                  <input
                    type="checkbox"
                    checked={checkedOptions[option.name] || false}
                    onChange={() => handleCheckboxChange(option)}
                    className="mr-2"
                  />
                  <div className="text-[12px]">{option.name}</div>
                  {option.name !== 'MOI' && (
                    <input
                      type="text"
                      value={inputValues[option.name] || ''}
                      onChange={(e) => handleInputChange(option, e)}
                      className="ml-4 border border-gray-300 text-black px-2 py-1 rounded text-[12px] w-[80px]"
                      placeholder="Enter score"
                    />
                  )}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default LanguageRequirementDrop;








