import React, { useRef, useEffect } from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';

const EducationModeDropdown = ({ options, selectedOption, onSelect, toggleDropdown, educationModeOpen, setEducationModeOpen }) => {
  const handleSelect = (option) => {
    onSelect(option);
    setEducationModeOpen(false);
  };
  const dropdownRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setEducationModeOpen(false); // Close the dropdown
      }
    };

    if (educationModeOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    // Cleanup event listener when component unmounts or dropdown closes
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [educationModeOpen]);
  return (
    <div>
    <div className="relative" ref={dropdownRef}>
      <div className="relative inline-block ">
        <div
          className="border border-gray-300  w-full md:w-[200px]   text-[12px] py-2 px-4 text-gray-700 leading-tight flex justify-between items-center outline-none"
          onClick={toggleDropdown}
        >
         <div className={`${!selectedOption ? 'text-gray-400' : 'text-black'}`}>
              {selectedOption ? selectedOption : 'Select Option'}
            </div>
          <div> {educationModeOpen? <FiChevronUp /> : <FiChevronDown />}</div>
        </div>
        {educationModeOpen && (
          <ul className="absolute z-10 mt-1 w-full bg-white border border-gray-400 rounded-md shadow-lg">
            {options.map((option, index) => (
              <li
                key={index}
                className="px-4 py-2 cursor-pointer hover:bg-black hover:text-white text-sm"
                onClick={() => handleSelect(option)}
              >
                <div className='text-[12px]'>{option.name}</div> {/* Display the name */}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  </div>
  )
}

export default EducationModeDropdown