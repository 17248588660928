import React, { useRef, useEffect } from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'; // Import icons for dropdown arrow

const StudyGapDropdown = ({ options, selectedOption, onSelect, toggleDropdown, studyGapOpen, setStudyGapOpen }) => {
  const handleSelect = (option) => {
    onSelect(option);
    setStudyGapOpen(false);
  };
  const dropdownRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setStudyGapOpen(false);
      }
    };

    // Add event listener if dropdown is open
    if (studyGapOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    // Cleanup the event listener when component unmounts or when dropdown closes
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [studyGapOpen]);

  return (
    <div>
      <div className="relative" ref={dropdownRef}>
        <div className="relative inline-block w-full ">
          <div
            className="border border-gray-300  w-full md:w-[200px]  text-[12px] py-2 px-4 text-gray-700 leading-tight flex justify-between items-center outline-none"
            onClick={toggleDropdown}
          >
            <div className={`${!selectedOption ? 'text-gray-400' : 'text-black'}`}>
              {selectedOption ? selectedOption : 'Select Option'}
            </div>
            <div> {studyGapOpen ? <FiChevronUp /> : <FiChevronDown />}</div>

          </div>
          {studyGapOpen && (
            <ul className="absolute z-10 mt-1 w-full md:w-[200px]   bg-white border border-gray-400 rounded-md shadow-lg max-h-[150px] overflow-y-auto">
              {options.map((option, index) => (
                <li
                  key={index}
                  className="px-4 py-2 cursor-pointer hover:bg-black hover:text-white text-sm"
                  onClick={() => handleSelect(option)}
                >
                  <div className='text-[12px]'>{option}</div>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  )
}

export default StudyGapDropdown