
import React, { useEffect, useState } from 'react';
import Header from '../Header/Header';
import SideBar from '../SideBar/SideBar';
import axiosInstance from '../axiosInstance/axiosInstance';
import AgencyDescription from './AgencyDescription';






const AgencyData = () => {
    
    const [searchQuery, setSearchQuery] = useState('');

    const [selectedAgency, setSelectedAgency] = useState(null);
    const [selectedStage, setSelectedStage] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [clients, setClients] = useState([]);
    const [isOpenStage, setIsOpenStage] = useState(false);
    const [isUserSideVisible, setIsUserSideVisible] = useState(false);
    const [isAgentSideVisible, setIsAgentSideVisible] = useState(false);
   
    const [loading, setLoading] = useState(false);
    const [offset, setOffset] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [menus, setMenu] = useState(false);
    const limit = 10;

    useEffect(() => {
        fetchClients(true);
    }, [selectedAgency, selectedStage, searchQuery]);

    useEffect(() => {
        const handleScroll = () => {
            if (window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - 1 && !loading && clients.length < totalCount) {
                fetchClients();
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [clients, totalCount, loading]);

    const fetchClients = async (reset = false) => {
        if (reset) {
            setOffset(0);
            setClients([]);
        }

        try {
            setLoading(true);
            const response = await axiosInstance.get('kanban/api/v1/clients/', {
                params: {
                    search: searchQuery || '',
                    agency: selectedAgency ? selectedAgency.id : '',
                    stage: selectedStage ? selectedStage.id : '',
                    limit: limit,
                    offset: reset ? 0 : offset,
                }
            });
            const newClients = reset ? response.data.results : [...clients, ...response.data.results];
            setClients(newClients);
            setTotalCount(response.data.count);
            setOffset(prevOffset => reset ? limit : prevOffset + limit);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching clients:', error);
           
            setLoading(false);
        }
    };


    const toggleAgentSideVisibility = () => {
        setIsAgentSideVisible(!isAgentSideVisible);


        localStorage.setItem('isAgentSideVisible', JSON.stringify(!isAgentSideVisible));
    };

    const toggleUserSideVisibility = () => {
        if (!isUserSideVisible) {
            setIsUserSideVisible(true);
            localStorage.setItem('isUserSideVisible', JSON.stringify(!isUserSideVisible));

        }

    };

    useEffect(() => {
        const storedUserVisibility = localStorage.getItem('isUserSideVisible');
        if (storedUserVisibility) {
            setIsUserSideVisible(JSON.parse(storedUserVisibility));
        }

        const storedAgentVisibility = localStorage.getItem('isAgentSideVisible');
        if (storedAgentVisibility) {
            setIsAgentSideVisible(JSON.parse(storedAgentVisibility));
        }
    }, []);

    const handleSearchInputChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleAgencySelection = (agency) => {
        setSelectedAgency(agency);
        setIsOpen(false);
    };

    const handleStageSelection = (stage) => {
        setSelectedStage(stage);
        setIsOpenStage(false);
    };
   
    useEffect(() => {
        const fetchIcons = async () => {
            try {
                const response = await axiosInstance.get('account/api/v1/module_menus/');
                setMenu(response.data);
            } catch (error) {
                console.error('Error fetching icons:', error);
               
            }
        };

        fetchIcons();
    }, []);





    return (
        <div className='relative'>

            <div>
                <Header
                    handleSearchInputChange={handleSearchInputChange}
                    handleAgencySelection={handleAgencySelection}
                    handleStageSelection={handleStageSelection}
                    selectedAgency={selectedAgency}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    setIsOpenStage={setIsOpenStage}
                    isOpenStage={isOpenStage}
                    selectedStage={selectedStage}
                    searchQuery={searchQuery}
                    menus={menus}
                />
            </div>
            <hr />
            <div className='flex '>
                <div className='  pt-8 '>
                    <SideBar
                        toggleUserSideVisibility={toggleUserSideVisibility}
                        toggleAgentSideVisibility={toggleAgentSideVisibility}
                        menus={menus}


                    />
                </div>
                <div className='flex-grow'>
                    

                    <div className='flex justify-center items-center h-screen flex-grow bg-gray-100'>
                        <AgencyDescription/>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default AgencyData;