
import React, { useRef, useEffect } from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';

const CountryDropdown = ({
    options,
    selectedOption,
    onSelect,
    toggleDropdown,
    dropdownOpen,
    setDropdownOpen
}) => {
    const dropdownRef = useRef(null); // Step 1: Create a reference for the dropdown container

    // Step 2: Close the dropdown when clicking outside of it
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownOpen(false); // Close the dropdown if clicked outside
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [setDropdownOpen]);
    return (
        <div className="relative " ref={dropdownRef}>

            <div
                className="inter-font block w-96 text-[11px] rounded-md py-4 bg-gray-100 border-gray-200 border-none placeholder-gray-400 focus:outline-none tracking-wide cursor-pointer"
                onClick={toggleDropdown}
            >

                <div className="flex justify-between items-center px-6">

                    <div className={selectedOption.name ? "text-black" : "text-gray-400"}>
                        {selectedOption.name ? selectedOption.name : "Select country here......"}
                    </div>


                    <div className='text-gray-700'>
                        {dropdownOpen ? <FiChevronUp style={{ color: 'gray', fontSize: '15px' }} /> : <FiChevronDown style={{ color: 'gray', fontSize: '15px' }} />}
                    </div>
                </div>
            </div>



            {dropdownOpen && (
                <div className="absolute z-10 mt-1 w-full bg-white border border-gray-200 rounded-md shadow-lg max-h-60 overflow-y-auto">
                    {selectedOption.name && (<div
                        className="px-4 py-2 text-sm hover:bg-gray-100 cursor-pointer"
                        onClick={() => {
                            onSelect({ id: '', name: '' }); // Clear selection
                            setDropdownOpen(false);
                        }}
                    >
                      <span className='text-[12px] text-black pt-sans-bold'>Clear Selection</span>  
                    </div>)}
                    {options.map((country, index) => (
                        <div
                            key={index}
                            className="px-4 py-2 text-sm hover:bg-gray-100 cursor-pointer"
                            onClick={() => {
                                onSelect(country);
                                setDropdownOpen(false);
                            }}
                        >
                            <div className='text-[12px] text-gray-600 pt-sans-bold'> {country.name} </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default CountryDropdown;
