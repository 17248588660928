import React, { useState } from 'react';
import { motion } from 'framer-motion';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the Quill CSS

const DescriptionModal = ({ isOpen, onClose, onSave }) => {
    const [description, setDescription] = useState("");

    const handleSave = () => {
        onSave(description);
        setDescription(""); // Clear the input after saving
        onClose(); // Close the modal
    };

    if (!isOpen) return null;

    return (
        <>
            {/* Background overlay */}
            <motion.div
                className="fixed inset-0 bg-black bg-opacity-70"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                onClick={onClose}
            />

            {/* Modal content */}
            <motion.div
                className="fixed inset-0 flex justify-center items-center"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
            >
                <div className="bg-white rounded-md shadow-md z-10 w-1/2 ">
                    <div className="py-4 px-4 mb-4 bg-black text-white text-2xl text-center font-bold">Add Description</div>
                    <div className='py-6 px-4'>
                        {/* Replace the textarea with ReactQuill */}
                        <ReactQuill
                            value={description}
                            onChange={setDescription}
                            placeholder="Enter Course Description"
                            className="rounded text-[12px]"
                            style={{ height: '250px' }} 
                            theme="snow" // Default theme with toolbar
                        />
                    </div>
                    <div className="flex justify-end gap-4 mt-4 py-4 px-4">
                        <button
                            className="bg-black text-white font-bold py-1 px-8 text-[13px] rounded-sm inter-light"
                            onClick={onClose}
                        >
                            Cancel
                        </button>
                        <button
                            className="bg-black text-white font-bold py-1 px-8 text-[13px] rounded-sm inter-light"
                            onClick={handleSave}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </motion.div>
        </>
    );
};

export default DescriptionModal;


