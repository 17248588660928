import React, { useState, useEffect } from 'react'
import SideBar from '../SideBar/SideBar'


import axiosInstance from '../axiosInstance/axiosInstance'

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import UniversityAgent from './UniversityAgent'
import UniversiyAgentSearch from './UniversityAgentSearch'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
const UniversityAgentDashboard = () => {
    const [isAddCountryVisible, setIsAddCountryVisible] = useState(false);
    const [isUpdateCountryVisible, setIsUpdateCountryVisible] = useState(false);
    const [isAddUniversityVisible, setIsAddUniversityVisible] = useState(false);
    const [isUpdateUniversityVisible, setIsUpdateUniversityVisible] = useState(false);
    const [universities, setUniversities] = useState([]);
    const [nextPage, setNextPage] = useState(null);
    const [prevPage, setPrevPage] = useState(null);
    const [searchCountry, setSearchCountry] = useState('');
    const [searchUniversity, setSearchUniversity] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [isMyUniversity, setIsMyUniversity] = useState(false);
    const [isBackButtonVisible, setIsBackButtonVisible] = useState(false);
    const [agencyUniversities, setAgencyUniversities] = useState([]);

    const [myUniversityCount, setMyUniversityCount] = useState(0); // State to store "My Universities" count
    const [totalUniversityCount, setTotalUniversityCount] = useState(0);
    const [totalPages, setTotalPages] = useState(1); // To store total pages
    const itemsPerPage = 10;
    const [totalEntries, setTotalEntries] = useState(0);


    const fetchAgencyUniversities = async () => {
        try {
            const response = await axiosInstance.get('home/api/v1/agency_universities/');

          
            if (response.data && response.data.results) {
                const agencyData = response.data.results;
                setAgencyUniversities(response.data.results);
               

            } else {
                console.warn("No results in the response");
            }
        } catch (error) {
            console.error('Error fetching agency universities:', error);
        }
    };


    const toggleAddCountryModal = () => {
        setIsAddCountryVisible(prev => !prev);
    };
    const toggleAddUniversityModal = () => {
        setIsAddUniversityVisible(prev => !prev);
    };
    const fetchUniversities = (url = 'home/api/v1/universities/?limit=10', country = '', university = '') => {
       
        axiosInstance.get(url, {
            params: {
                country: country,
                search_university: university,
            },
        })
            .then(response => {
                setIsMyUniversity(false);
                setUniversities(response.data.results);
                setNextPage(response.data.next);
             
                setPrevPage(response.data.previous);
                setTotalUniversityCount(response.data.count);
                const totalCount = response.data.count; // Get the total count of universities
                setTotalPages(Math.ceil(totalCount / itemsPerPage));
                setTotalEntries(totalCount);
            })
            .catch(error => {
                console.error("There was an error fetching the university data!", error);
            });
    };


    useEffect(() => {
        fetchUniversities('home/api/v1/universities/?limit=10', searchCountry, searchUniversity);
    }, [searchCountry, searchUniversity]);



    const handleNext = () => {
        if (nextPage) {
            fetchUniversities(nextPage);
            setCurrentPage((prev) => prev + 1);
        }
    };

    // Handle "Previous" button click
    const handlePrevious = () => {
        if (prevPage) {
            fetchUniversities(prevPage);
            setCurrentPage((prev) => prev - 1);
        }
    };
    const fetchMyUniversities = (country = '', search_university = '') => {
        const myUniversityUrl = 'home/api/v1/agency_universities/?limit=10';

        axiosInstance.get(myUniversityUrl, {
            params: {
                country,
                search_university,
            },
        })
            .then(response => {
                setUniversities(response.data.results);
                


                setNextPage(response.data.next);
                setPrevPage(response.data.previous);
                const totalCount = response.data.count; // Get the total count of universities
                setMyUniversityCount(totalCount); // Total count for My Universities
                setTotalEntries(totalCount); // Set total entries for My Universities
                setTotalPages(Math.ceil(totalCount / itemsPerPage)); // Set total pages based on the limit
                setCurrentPage(1); // Reset to first page when fetching My Universities
                // setIsMyUniversity(true); // Set this to true to indicate "My Universities" is active
                setIsBackButtonVisible(true); // Show back button
            })
            .catch(error => {
                console.error("There was an error fetching the my university data!", error);
            });
    };


    // const fetchMyUniversities = (country = '', search_university = '') => {
    //     const myUniversityUrl = 'home/api/v1/agency_universities/?limit=4';

    //     axiosInstance.get(myUniversityUrl, {
    //         params: {
    //             country,
    //             search_university,
    //         },
    //     })
    //         .then(response => {
    //             setUniversities(response.data.results);
    //             setNextPage(response.data.next);
    //             setPrevPage(response.data.previous);

    //             setIsMyUniversity(true);
    //             setIsBackButtonVisible(true);
    //             // Set this to true to indicate "My Universities" is active
    //         })
    //         .catch(error => {
    //             console.error("There was an error fetching the my university data!", error);
    //         });
    // };
    useEffect(() => {
        // Fetch universities based on searchCountry or searchUniversity when the component mounts or updates
        if (!isMyUniversity) {
            fetchUniversities('', searchCountry, searchUniversity);
        }
    }, [searchCountry, searchUniversity, isMyUniversity]);
    useEffect(() => {
        fetchMyUniversities()

    }, [])


    const handleMyUniversitiesClick = () => {
        setIsMyUniversity(true);  // Mark "My Universities" as active
        setCurrentPage(1);  // Reset pagination
        fetchMyUniversities(); // Call the separate API for "My Universities"
    };
    const handleBackButtonClick = () => {
        setIsMyUniversity(false);
        setIsBackButtonVisible(false);
        setCurrentPage(1);
        fetchUniversities();
    };





    return (
        <div>
            <div className='flex bg-gray-50 h-full'>
                <div className='pt-8 bg-white h-full sticky top-0 '>
                    <SideBar />
                </div>
                <div className='flex flex-col px-4  overflow-y-auto  w-full '>

                    {isMyUniversity ? (


                        <div className=' flex  px-8 pt-2 py-4 gap-4'>
                            {/* {isBackButtonVisible && (
    <button className="bg-black text-white text-[12px] px-4 py-1 rounded pt-sans-bold"   >
        <ArrowBackIcon style={{ fontSize: '15px', fontWeight: 'bold' }} />
    </button>
)} */}
                             <div className='flex gap-4'>

                            <button className="border border-black  text-[12px] px-4 py-3 rounded pt-sans-bold  text-black " onClick={handleBackButtonClick}>
                                All Universities ({totalUniversityCount})
                            </button>
                            <button className="border border-black  text-[12px] px-4 py-3 rounded pt-sans-bold bg-black text-white " onClick={handleMyUniversitiesClick}>
                                My Universities ({myUniversityCount}/5)
                            </button>
                             </div>

                            {/* <div>
                            {isMyUniversity&&(<button class="px-6 py-2 bg-green-200 text-black border-2 border-black font-semibold  hover:ring-2 hover:ring-blue-500">
              Add Money
                </button>)}
                            </div> */}
                           
                        </div>
                    ) : (



                        <div className=' flex  px-8 pt-2 py-2 gap-4'>
                            <div className='flex gap-4'>

                            {/* {isBackButtonVisible && (
                            <button className="bg-black text-white text-[12px] px-4 py-1 rounded pt-sans-bold"   >
                                <ArrowBackIcon style={{ fontSize: '15px', fontWeight: 'bold' }} />
                            </button>
                        )} */}
                        
                            <button className="border border-black  text-[12px] px-4 py-3 rounded pt-sans-bold bg-black text-white " onClick={handleBackButtonClick}>
                                All Universities ({totalUniversityCount})
                            </button>
                            <button className="border border-black  text-[12px] px-4 py-3 rounded pt-sans-bold   text-black " onClick={handleMyUniversitiesClick}>
                            My Universities ({myUniversityCount}/5)
                            </button>

                            </div>
                           
                        </div>
                    )

                    }


                    <div className='shadow-md bg-white flex flex-col gap-2 pt-1 pb-4 px-4 h-full'>
                        <UniversiyAgentSearch toggleaddmoney={isMyUniversity} toggleAddCountryModal={toggleAddCountryModal} toggleAddUniversityModal={toggleAddUniversityModal} onSearchCountry={setSearchCountry}
                            onSearchUniversity={setSearchUniversity} searchUniversity={searchUniversity} searchCountry={searchCountry} />
                        <UniversityAgent setIsMyUniversity={setIsMyUniversity} isAddCountryVisible={isAddCountryVisible} setIsAddCountryVisible={setIsAddCountryVisible} setIsAddUniversityVisible={setIsAddUniversityVisible} isAddUniversityVisible={isAddUniversityVisible} universities={universities} setUniversities={setUniversities} fetchUniversities={fetchUniversities} prevPage={prevPage} nextPage={nextPage} isUpdateCountryVisible={isUpdateCountryVisible} setIsUpdateCountryVisible={setIsUpdateCountryVisible} isUpdateUniversityVisible={isUpdateUniversityVisible} setIsUpdateUniversityVisible={setIsUpdateUniversityVisible} isMyUniversity={isMyUniversity} fetchMyUniversities={fetchMyUniversities} agencyUniversities={agencyUniversities} fetchAgencyUniversities={fetchAgencyUniversities} setAgencyUniversities={setAgencyUniversities} />

                    </div>
                    <div className="flex gap-2 py-6 items-center  justify-between ">
                        <div className="text-[11px] px-4 py-0.5 text-gray-500 pt-sans-bold">
                            {`Showing ${universities.length > 0 ? 1 : 0} to  ${totalPages}  of ${totalEntries} entries`}
                        </div>

                        <div className='flex gap-1'>
                            <div className='flex gap-1'>
                                <div>
                                    <button
                                        className=" border border-gray-600  text-gray-700  rounded-sm pt-sans-bold"
                                        onClick={handlePrevious}
                                    >
                                        <KeyboardDoubleArrowLeftIcon style={{ fontSize: '20px' }} />
                                    </button>

                                </div>
                                <div>

                                    <button
                                        className=" border border-gray-600  text-gray-700  rounded-sm pt-sans-bold"
                                        onClick={handlePrevious}
                                    >
                                        <KeyboardArrowLeftIcon style={{ fontSize: '20px' }} />
                                    </button>

                                </div>



                            </div>

                            <div className=" text-[14px] bg-black px-4 py-0.5 text-white pt-sans-bold">{currentPage}</div>
                            {nextPage && (
                                <div>
                                    <button
                                        className="border border-gray-600 text-gray-700 rounded-sm pt-sans-bold"
                                        onClick={handleNext}
                                    >
                                        < KeyboardArrowRightIcon style={{ fontSize: '20px' }} />

                                    </button>
                                </div>
                            )}
                            {nextPage && (
                                <div>
                                    <button
                                        className="border border-gray-600 text-gray-700 rounded-sm pt-sans-bold"
                                        onClick={handleNext}
                                    >
                                        <KeyboardDoubleArrowRightIcon style={{ fontSize: '20px' }} />
                                    </button>
                                </div>
                            )}

                        </div>



                        <div className='flex justify-end items-center gap-6 items-end'>
                            <div>
                                <div className='pt-sans-bold text-[12px] text-gray-500'>Cost per lead is Rs.35</div>
                            </div>
                            <div className="text-[12px] flex px-0.5 border border-1 border-gray-400 py-0.5 text-gray-500 pt-sans-bold">
                                <div>
                                    {`${totalPages}`}

                                </div>
                                <div>
                                    <KeyboardArrowDownIcon style={{ fontSize: '18px' }} />

                                </div>


                            </div>

                        </div>





                    </div>


                </div>


            </div>





        </div>
    )
}

export default UniversityAgentDashboard