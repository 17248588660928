import React, { useState, useRef, useEffect } from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'; // Import icons for dropdown arrow

const EducationLevelDropdown = ({ options, selectedOption, onSelect, toggleDropdown, educationOpen, setEducationOpen }) => {
  const handleSelect = (option) => {
    onSelect(option);
    setEducationOpen(false);
  };
  const dropdownRef = useRef(null);
  useEffect(() => {
    // Function to handle click outside
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        // If clicked outside dropdown, close it
        setEducationOpen(false);
      }
    };

    // Add event listener when dropdown is open
    if (educationOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    // Clean up the event listener when dropdown is closed or component is unmounted
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [educationOpen, setEducationOpen]);

  return (
    <div>
      <div className="relative" ref={dropdownRef}>
        <div className="relative inline-block w-full">
          <div
            className="border border-gray-300  w-full md:w-[200px]  text-[12px] py-2 px-4 text-gray-700 leading-tight flex justify-between items-center outline-none"
            onClick={toggleDropdown}
          >
            <div className={`${!selectedOption ? 'text-gray-400' : 'text-black'}`}>
              {selectedOption ? selectedOption : 'Select Option'}
            </div>
            <div> {educationOpen ? <FiChevronUp /> : <FiChevronDown />}</div>
          </div>
          {educationOpen && (
            <ul className="absolute z-10 mt-1 w-full bg-white border border-gray-400 rounded-md shadow-lg">
              {options.map((option, index) => (
                <li
                  key={index}
                  className="px-4 py-2 cursor-pointer hover:bg-black hover:text-white text-sm"
                  onClick={() => handleSelect(option)}
                >
                  <div className='text-[12px]'>{option.name}</div> {/* Display the name */}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default EducationLevelDropdown;
