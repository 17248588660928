import React from 'react';


import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const UniversityHeader = ({unassignedCount, fetchUnassignedUniversities, fetchUniversities, isUnassignedView, setIsUnassignedView,universityCount }) => {
    const handleBack = () => {
        setIsUnassignedView(true); // Disable Unassigned View
        fetchUniversities(); // Call the function to fetch regular universities
    };
    const unassignedClick=()=>{
        setIsUnassignedView(false)
        fetchUnassignedUniversities()
    }

    return (
        <div>
            <div className='flex gap-6'>
                {isUnassignedView ? (


                    <div className='flex  gap-4'>
                         <div>
                            <button className="bg-black text-white text-[12px] px-4 py-3 rounded pt-sans-bold" onClick={handleBack} >
                                {/* <ArrowBackIcon style={{ fontSize: '15px', fontWeight: 'bold' }} /> */}
                                All Universities ({universityCount})
                            </button>
                        </div>
                        <div>
                            <button className="border border-black  text-black text-[12px] px-4 py-2.5 rounded pt-sans-bold" onClick={unassignedClick}>
                                Unassigned Universities ({unassignedCount})
                            </button>
                        </div>
                       
                        {/* <div>
                            <button className="border border-1 border-black text-[12px]  text-black px-4 py-2 rounded pt-sans-bold ">
                                Associates
                            </button>

                        </div> */}
                    </div>




                ) : (
                    <>
                     <div>
                            <button className=" border border-1 border-black text-[12px] px-4 py-3 rounded pt-sans-bold" onClick={handleBack}>
                                {/* <ArrowBackIcon style={{ fontSize: '15px', fontWeight: 'bold' }} /> */}
                                All Universities({universityCount})
                            </button>
                        </div>
                        <div>
                            <button className="bg-black text-white text-[12px] px-4 py-3 rounded pt-sans-bold" onClick={unassignedClick}>
                                Unassigned Universities({unassignedCount})
                            </button>
                        </div>
                       
                        {/* <div>
                            <button className="border border-1 border-black text-[12px]  text-black px-4 py-2 rounded pt-sans-bold ">
                                Associates
                            </button>

                        </div> */}
                    </>

                )}
            </div>
        </div>
    );
};

export default UniversityHeader;