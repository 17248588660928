import React, { useState, useEffect } from 'react';
import axios from 'axios';
import axiosInstance from '../axiosInstance/axiosInstance';
import { motion } from 'framer-motion';

const UpdateStaff = ({ selectedUserData, setSelectedUserData, onClose, setModalVisible, fetchStaffData , setUpdateStaffModal }) => {
    const [options, setOptions] = useState({
        categories: [],
        designations: []

    });
    const [showButton, setShowButton] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);



    useEffect(() => {


        const fetchOptions = async () => {

            try {

                const categoriesResponse = await axiosInstance.get('kanban/api/v1/category_dropdown/?limit=&search=');
                setOptions(prevOptions => ({
                    ...prevOptions,
                    categories: categoriesResponse.data
                }));



            } catch (error) {
                console.error('Error fetching options:', error);
            }
        };
        const fetchDesignationOptions = async () => {

            try {

                const designationsResponse = await axiosInstance.get('/kanban/api/v1/designation_dropdown/');
                setOptions(prevOptions => ({
                    ...prevOptions,
                    designations: designationsResponse.data
                }));



            } catch (error) {
                console.error('Error fetching options:', error);
            }
        };

        fetchOptions();
        fetchDesignationOptions();



    }, []);
    const handleCloseModal = () => {


        setUpdateStaffModal(false);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setSelectedUserData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };



    const uploadImage = async (file) => {
        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await axiosInstance.post('home/api/v1/upload-file/', formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });
            return response.data.image_url; // Return the URL if successful
        } catch (error) {
            console.error('Error uploading image:', error);
            return null; // Return null if an error occurs
        }
    };


    const handleLogoChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            const uploadedUrl = await uploadImage(file);
            setSelectedUserData((prev) => ({
                ...prev,
                logo: file,
                logo: uploadedUrl,
            }));
        }
    };
    const handleBannerChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            const uploadedUrl = await uploadImage(file);
            setSelectedUserData((prev) => ({
                ...prev,
                banner: uploadedUrl,
            }));
        }
    };




    const handleUserSubmit = async (e) => {
        e.preventDefault();

        try {
            const clientCategory = selectedUserData.category ? selectedUserData.category.id : null
            const clientDesignation = selectedUserData.designation ? selectedUserData.designation.id : null

            const userUpdateForm = {
                'first_name': selectedUserData.first_name,
                'phone': selectedUserData.phone,
                'email': selectedUserData.email,
                'username': selectedUserData.email,
                logo: selectedUserData.logo, // Include the logo URL
                banner: selectedUserData.banner, // Include the banner URL
                'category': clientCategory ? parseInt(clientCategory) : selectedUserData.category,
                'designation': clientDesignation ? parseInt(clientDesignation) : selectedUserData.designation,


            }
            console.log(selectedUserData);

            await axiosInstance.put(`/account/api/v1/user-detail-update/${selectedUserData.id}/`, userUpdateForm);
            if (userUpdateForm.status = 200) {
                console.log("Agency updated successfully");
                fetchStaffData(true);
            }

            setUpdateStaffModal(false)


        } catch (error) {
            console.error('Error updating data:', error);

        }
    };
    useEffect(() => {

        if (selectedUserData && selectedUserData.first_login) {
            setShowButton(true);

            if (selectedUserData.someConditionToDisableButton) {
                setIsButtonDisabled(true);
            }
        }
    }, [selectedUserData]);
    const handleButtonClick = async () => {
        try {

            const response = await axiosInstance.post('account/api/v1/resend-password-mail/', { user_id: selectedUserData.agency_id });
            console.log('Response:', response.data);


            setIsButtonDisabled(true);

        } catch (error) {
            console.error('Error:', error);
        }
    };


    return (
        <div>
            <motion.div
                className=" fixed inset-0 bg-black bg-opacity-70"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                onClick={onClose}
            />
            <motion.div
                className=" p-6   z-10  "

                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
            >

                <div className="fixed top-80 left-1/2  transform -translate-x-1/2 -translate-y-1/2">
                    <div className='bg-blue-50 flex items-center justify-center '>
                        <span className="text-2xl font-bold  px-6  py-6 text-black">
                            Update User
                        </span>
                    </div>
                    <form className="w-[300px] md:w-[400px] mx-auto bg-white shadow-xl px-6 py-4 ">


                        <div className="mb-2">
                            <label htmlFor="name" className=" block mb-2 text-sm font-medium text-black ">Name</label>
                            <input
                                type="text"
                                id="first_name"
                                name="first_name"
                                value={selectedUserData?.first_name || ''}
                                onChange={handleInputChange}
                                className=" bg-blue-50 border text-black   text-sm rounded-lg outline-none w-full p-2.5"
                                placeholder="Enter Name"
                            />

                        </div>

                        <div className="mb-2">
                            <label htmlFor="phoneNumber" className=" block mb-2 text-sm font-medium text-black ">Phone Number</label>
                            <input
                                type="text"
                                id="phone"
                                name="phone"
                                value={selectedUserData?.phone || ''}
                                onChange={handleInputChange}
                                className=" bg-blue-50 border text-black text-sm rounded-lg outline-none w-full p-2.5"
                                placeholder="Enter Phone Number"
                            />

                        </div>
                        <div className="mb-2">
                            <label htmlFor="name" className=" block mb-2 text-sm font-medium text-black ">Email</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={selectedUserData?.email || ''}
                                onChange={handleInputChange}
                                className=" bg-blue-50 border text-black   text-sm rounded-lg outline-none w-full p-2.5"
                                placeholder="Enter email"
                            />

                        </div>
                        <div className="mb-2">
                            <label htmlFor="category" className=" block mb-2 text-sm font-medium text-black ">Designation</label>
                            <select
                                id="designation"
                                name="designation"
                                value={selectedUserData?.designation || ''}
                                onChange={handleInputChange}
                                className="poppins-semiboldbg-green-50 border text-black text-sm rounded-lg outline-none w-full p-2.5"
                            >
                                <option value={selectedUserData.designation?.name}>{selectedUserData.designation ? selectedUserData.designation?.name : "select designation "}</option>
                                {options.designations.map(designation => (
                                    <option key={designation.id} value={designation.id}>{designation.name} </option>
                                ))}
                            </select>

                        </div>
                        <div className="mb-2">
                            <label htmlFor="category" className=" block mb-2 text-sm font-medium text-black ">Category</label>
                            <select
                                id="category"
                                name="category"
                                value={selectedUserData?.category || ''}
                                onChange={handleInputChange}
                                className=" bg-green-50 border text-black text-sm rounded-lg outline-none w-full p-2.5"
                            >
                                <option value={selectedUserData.category?.name}>{selectedUserData.category ? selectedUserData.category?.name : "select category "}</option>
                                {options.categories?.map(category => (
                                    <option key={category.id} value={category.id}>{category.name} </option>
                                ))}
                            </select>

                        </div>
                        {/* <div className='grid grid-cols-2 gap-2'>


                            <div className="flex flex-col gap-2">
                                <label htmlFor="logo" className="block inter-light text-[12px]">Agency Logo:</label>
                                <div className="flex items-center justify-center w-full">
                                    <label className="flex items-center justify-center bg-blue-50 w-full h-32 border-2 border-dashed border-gray-300 rounded-lg cursor-pointer hover:border-black">
                                        {selectedUserData.logo ? (
                                            <img src={selectedUserData.logo} alt="Logo Preview" className="h-full w-full object-contain" />
                                        ) : (
                                            <div className='flex flex-col items-center'>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="w-10 h-10 text-gray-400"
                                                    viewBox="0 0 20 20"
                                                    fill="currentColor"
                                                >
                                                    <path
                                                        fillRule="evenodd"
                                                        d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm10 7a1 1 0 00-1-1H7a1 1 0 100 2h6a1 1 0 001-1z"
                                                        clipRule="evenodd"
                                                    />
                                                </svg>
                                                <span className="mt-2 inter-light text-[12px] text-gray-500">
                                                    Click to upload logo 
                                                </span>




                                            </div>


                                        )}
                                        <input type="file" id="logo" onChange={handleLogoChange} className="hidden" />
                                    </label>
                                </div>
                            </div>


                            <div className="flex flex-col gap-2 mb-4">
                                <label htmlFor="banner" className="block inter-light text-[12px]">Banner Image:</label>
                                <label className="flex items-center justify-center bg-blue-50 w-full h-32 border-2 border-dashed border-gray-300 rounded-lg cursor-pointer hover:border-black">
                                    {selectedUserData.banner ? (
                                        <img src={selectedUserData.banner} alt="Banner Preview" className="h-full w-full object-contain" />
                                    ) : (
                                        <div className='flex flex-col items-center'>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="w-10 h-10 text-gray-400"
                                                viewBox="0 0 20 20"
                                                fill="currentColor"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm10 7a1 1 0 00-1-1H7a1 1 0 100 2h6a1 1 0 001-1z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                            <span className="mt-2 inter-light text-[12px] text-gray-500">
                                                Click to upload Banner 
                                            </span>
                                        </div>
                                    )}
                                    <input type="file" id="banner" onChange={handleBannerChange} className="hidden" />
                                </label>
                            </div>
                        </div> */}
                        <div className="flex justify-end pt-4">
                            {
                                showButton && (
                                    <button
                                        type="button"
                                        onClick={handleButtonClick}
                                        disabled={isButtonDisabled}
                                        className={` text-blue-600 border border-blue-700 focus:outline-none focus:ring-blue-300 text-sm font-semibold rounded-lg text-md px-5 text-center me-2 mb-2 dark:border-blue-500 dark:focus:ring-blue-800 ${isButtonDisabled ? ' text-blue-500   hover:text-white hover:bg-blue-600 text-white cursor-not-allowed ' : 'hover:text-white hover:bg-blue-800 dark:hover:text-white dark:hover:bg-blue-800'}`}
                                    >
                                        Resend Mail
                                    </button>
                                )
                            }



                            <button
                                type="button"
                                onClick={handleUserSubmit}


                                className="text-blue-600 hover:text-white border border-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-blue-300 text-sm font-semibold rounded-lg text-md px-5  text-center me-2 mb-2 dark:border-blue-500  dark:hover:text-white dark:focus:ring-blue-800"
                            >
                                Update Staff
                            </button>
                            <button
                                type="button"
                                onClick={handleCloseModal}
                                className="text-red-700 hover:text-white border border-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-red-500 dark:text-red-500 dark:hover:text-white dark:hover:bg-red-600 dark:focus:ring-red-900"
                            >
                                Close
                            </button>
                        </div>

                    </form>
                </div>
            </motion.div >
        </div>

    )
}

export default UpdateStaff