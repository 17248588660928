import React, { useState, useEffect } from 'react';
import axiosInstance from '../axiosInstance/axiosInstance';
import { motion } from 'framer-motion';
import Modal from 'react-modal';
const AddUser = ({ setIsAddUserVisible, onClose, setSelectedUserData, fetchData }) => {
    const [userData, setUserData] = useState({
        first_name: '',
        phone: '',
        email: '',
        category: '',
        designation: '',
        username: '',
        is_agency: true,
        walletamount: '',
        is_staff: false,
        profile_img: null,

        banner_image
            : null,
    });

    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [options, setOptions] = useState({
        categories: [],
        designations: []
    });



    // const uploadImage = async (file) => {
    //     const formData = new FormData();
    //     formData.append('file', file);

    //     try {
    //         const response = await axiosInstance.post('home/api/v1/upload-file/', formData, {
    //             headers: { 'Content-Type': 'multipart/form-data' },
    //         });
    //         return response.data.image_url;
    //     } catch (error) {
    //         console.error('Error uploading image:', error);

    //         return null;
    //     }
    // };

    const uploadImage = async (file) => {
        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await axiosInstance.post('home/api/v1/upload-file/', formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });
            return response.data.image_url; // Return the URL if successful
        } catch (error) {
            console.error('Error uploading image:', error);
            return null; // Return null if an error occurs
        }
    };


    const handleLogoChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            const uploadedUrl = await uploadImage(file);
            setUserData((prev) => ({
                ...prev,
                logo: file,
                profile_img: uploadedUrl,
            }));
        }
    };
    const handleBannerChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            const uploadedUrl = await uploadImage(file);
            setUserData((prev) => ({
                ...prev,
                banner_image: uploadedUrl,
            }));
        }
    };


    const validateForm = () => {
        const newErrors = {};
        if (!userData.first_name || !userData.first_name.trim()) {
            newErrors.first_name = 'Name is required';
        }
        if (!userData.phone || !userData.phone.trim()) {
            newErrors.phone = 'Phone Number is required';
        }
        // if (!userData.walletamount || !userData.walletamount.trim()) {
        //     newErrors.walletamount = 'wallet ammount is required';
        // }
        if (!userData.email || !userData.email.trim()) {
            newErrors.email = 'Email is required';
        } else if (errors.email) {
            newErrors.email = errors.email;
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };


    const handleUserSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            setIsLoading(true);
            try {
                const response = await axiosInstance.post('/account/api/v1/users/', userData);
                setIsAddUserVisible(false);
                console.log('Response from API:', response.data);
                if (response.status === 201) {
                    console.log("User added successfully");
                    fetchData();
                    setUserData({
                        first_name: '',
                        phone: '',
                        email: '',
                        category: '',
                        designation: '',
                        username: '',
                        is_staff: false,
                        wallentamount: '',
                        is_agency: false,
                        profile_img: null,
                        banner_image: null,
                    });
                    setIsSuccess(true);
                    onClose();
                }
            } catch (error) {
                console.error('Error submitting form:', error);
                if (error.response && error.response.data) {
                    const { status, error: errorMessage } = error.response.data;
                    if (status === "0" && errorMessage) {
                        setErrors(prevErrors => ({
                            ...prevErrors,
                            email: errorMessage
                        }));
                    }
                }
            } finally {
                setIsLoading(false);
            }
        }
    };

    useEffect(() => {
        const fetchOptions = async () => {
            try {
                const categoriesResponse = await axiosInstance.get('kanban/api/v1/category_dropdown/?limit=&search=');
                const designationsResponse = await axiosInstance.get('/kanban/api/v1/designation_dropdown/');

                setOptions({
                    categories: categoriesResponse.data,
                    designations: designationsResponse.data,
                });
            } catch (error) {
                console.error('Error fetching options:', error);
            }
        };

        fetchOptions();
    }, []);


    const handleChange = (e) => {
        const { name, value } = e.target;
        console.log('Name:', name, 'Value:', value);

        setUserData(prevUserData => ({
            ...prevUserData,
            [name]: value,
            username: name === 'email' ? value : prevUserData.email,
        }));

        // Clear errors for 'name' and 'phone' fields
        if (name === 'first_name' || name === 'phone') {
            setErrors(prevErrors => ({
                ...prevErrors,
                [name]: '',
            }));
        } else if (name === 'email') {
            setErrors(prevErrors => ({
                ...prevErrors,
                email: '',
            }));
        }

        console.log('Current Errors:', errors); // This will log the previous state due to closure
    };





    const handleRadioChange = (e) => {
        const { value } = e.target;
        setUserData(prevUserData => ({
            ...prevUserData,
            is_staff: value === 'normalStaff',
            is_agency: value === 'agency',
        }));
    };

    const handleClose = () => {
        onClose();
        setIsAddUserVisible(false);
    };

    return (
        <div>
            <motion.div
                className=" fixed inset-0 bg-black bg-opacity-70"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                onClick={onClose}
            />
            <motion.div
                className=" p-6   z-10  "

                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}

            >
                <div className="fixed inset-0   flex items-center justify-center z-50 ">
                    <div>
                        {isSuccess ? (
                            <div className="flex flex-col items-center justify-center">
                                <p className="text-lg font-semibold text-green-500">User added successfully!</p>
                                <button
                                    onClick={handleClose}
                                    className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-md"
                                >
                                    Close
                                </button>
                            </div>
                        ) : (
                            <div className=" w-[400px]">
                                <div className='bg-blue-50 flex items-center justify-center' >
                                    <span className="text-2xl font-bold px-6 py-6 text-black">
                                        Add Agency
                                    </span>
                                </div>
                                <form className=" mx-auto bg-white shadow-xl px-6 py-4 " onSubmit={handleUserSubmit}>



                                    <div className="mb-2">
                                        <label htmlFor="first_name" className="block mb-2 text-sm font-medium text-black">Name</label>
                                        <input
                                            type="text"
                                            id="first_name"
                                            name="first_name"
                                            value={userData.first_name}
                                            onChange={handleChange}
                                            className="bg-blue-50 border text-black text-sm rounded-lg outline-none w-full p-2.5"
                                            placeholder="Enter Name"
                                        />
                                        {errors.first_name && <div className="text-red-500 text-[13px] text-left">{errors.first_name}</div>}
                                    </div>

                                    <div className="mb-2">
                                        <label htmlFor="phone" className="block mb-2 text-sm font-medium text-black">Phone Number</label>
                                        <input
                                            type="text"
                                            id="phone"
                                            name="phone"
                                            value={userData.phone}
                                            onChange={handleChange}
                                            className="bg-blue-50 border text-black text-sm rounded-lg outline-none w-full p-2.5"
                                            placeholder="Enter Phone Number"
                                        />
                                        {errors.phone && <div className="text-red-500 text-[13px] text-left">{errors.phone}</div>}
                                    </div>




                                    <div className="mb-2">
                                        <label htmlFor="email" className="block mb-2 text-sm font-medium text-black">Email</label>
                                        <input
                                            type="email"
                                            id="email"
                                            name="email"
                                            value={userData.email}
                                            onChange={handleChange}
                                            className="bg-blue-50 border text-black text-sm rounded-lg outline-none w-full p-2.5"
                                            placeholder="Enter email"
                                        />
                                        {errors.email && <div className="text-red-500 text-[13px] text-left">{errors.email}</div>}
                                    </div>
                                    {/* <div className="mb-2">
                                        <label htmlFor="email" className="block mb-2 text-sm font-medium text-black">Wallent Amount</label>
                                        <input
                                            type="text"
                                           
                                            name="walletamount"
                                            value={userData.walletamount}
                                            onChange={handleChange}
                                            className="bg-blue-50 border text-black text-sm rounded-lg outline-none w-full p-2.5"
                                            placeholder="Enter wallet ammount"
                                        />
                                        {errors.walletamount && <div className="text-red-500 text-[13px] text-left">{errors.walletamount}</div>}
                                    </div> */}

                                    {/* <div className="mb-2">
                                        <label htmlFor="designation" className="block mb-2 text-sm font-medium text-black">Designation</label>
                                        <select
                                            id="designation"
                                            name="designation"
                                            value={userData.designation}
                                            onChange={handleChange}
                                            className="bg-green-50 border  text-sm rounded-lg outline-none w-full p-2.5"
                                        >
                                            <option className='text-gray-400' value="">Select designation</option>
                                            {options.designations.map(designation => (
                                                <option key={designation.id} value={designation.id}>{designation.name}</option>
                                            ))}
                                        </select>
                                    </div> */}

                                    {/* <div className="mb-2">
                                        <label htmlFor="category" className="block mb-2 text-sm font-medium text-black">Category</label>
                                        <select
                                            id="category"
                                            name="category"
                                            value={userData.category}
                                            onChange={handleChange}
                                            className="bg-green-50 border  text-sm rounded-lg outline-none w-full p-2.5"
                                        >
                                            <option className='text-gray-500' value="">Select category</option>
                                            {options.categories.map(category => (
                                                <option key={category.id} value={category.id}>{category.name}</option>
                                            ))}
                                        </select>
                                    </div> */}

                                    {/* <div className="mb-2">
                                        <span className="block mb-2 text-sm font-medium text-black">User Type</span>
                                        <div className='flex gap-2 items-center'>
                                            <div className="flex items-center">
                                                <input
                                                    type="radio"
                                                    id="normalStaff"
                                                    name="userType"
                                                    value="normalStaff"
                                                    checked={userData.is_staff && !userData.is_agency}
                                                    onChange={handleRadioChange}
                                                    className="mr-2"
                                                />
                                                <label htmlFor="normalStaff" className="text-sm font-medium text-black">Normal Staff</label>
                                            </div>
                                            <div className="flex items-center">
                                                <input
                                                    type="radio"
                                                    id="agency"
                                                    name="userType"
                                                    value="agency"
                                                    checked={userData.is_agency}
                                                    onChange={handleRadioChange}
                                                    className="mr-2"
                                                />
                                                <label htmlFor="agency" className="text-sm font-medium text-black">Agency</label>
                                            </div>
                                        </div>
                                    </div> */}


                                    <div className='grid grid-cols-2 gap-2'>


                                        <div className="flex flex-col gap-2">
                                            <label htmlFor="logo" className="block inter-light text-[12px]">Agency Logo:</label>
                                            <div className="flex items-center justify-center w-full">
                                                <label
                                                    htmlFor="logo"
                                                    className="flex flex-col items-center bg-blue-50 justify-center w-full h-32 border-2 border-dashed border-gray-300 rounded-lg cursor-pointer hover:border-black relative"
                                                >
                                                    {userData.profile_img ? (
                                                        <img
                                                            src={userData.profile_img}
                                                            alt="Uploaded logo preview"
                                                            className="h-full w-full object-contain rounded-md px-2 py-2"
                                                        />
                                                    ) : (
                                                        <>
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                className="w-10 h-10 text-gray-400"
                                                                viewBox="0 0 20 20"
                                                                fill="currentColor"
                                                            >
                                                                <path
                                                                    fillRule="evenodd"
                                                                    d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm10 7a1 1 0 00-1-1H7a1 1 0 100 2h6a1 1 0 001-1z"
                                                                    clipRule="evenodd"
                                                                />
                                                            </svg>
                                                            <span className="mt-2 inter-light text-[12px] text-gray-500">
                                                                Click to upload logo
                                                            </span>
                                                        </>
                                                    )}
                                                    <input
                                                        id="logo"
                                                        type="file"
                                                        accept="image/*"
                                                        onChange={handleLogoChange}
                                                        className="hidden bg-blue-50"
                                                    />
                                                </label>
                                            </div>
                                        </div>


                                        <div className="flex flex-col gap-2 mb-4">
                                            <label htmlFor="banner" className="block inter-light text-[12px]">Banner Image:</label>
                                            <label className="flex flex-col items-center justify-center bg-blue-50 w-full h-32 border-2 border-dashed border-gray-300 rounded-lg cursor-pointer hover:border-black">
                                                {userData.banner_image ? (
                                                    <img src={userData.banner_image} alt="Uploaded banner" className="h-full w-full object-contain rounded-md" />
                                                ) : (
                                                    <>
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            className="w-10 h-10 text-gray-400"
                                                            viewBox="0 0 20 20"
                                                            fill="currentColor"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm10 7a1 1 0 00-1-1H7a1 1 0 100 2h6a1 1 0 001-1z"
                                                                clipRule="evenodd"
                                                            />
                                                        </svg>
                                                        <span className="mt-2 inter-light text-[12px] text-gray-500">
                                                            Click to Banner Image
                                                        </span>
                                                    </>
                                                )}
                                                <input
                                                    id="banner"
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={handleBannerChange}
                                                    className="hidden"
                                                />
                                            </label>
                                        </div>
                                    </div>

                                    <div className="flex justify-end pt-4">
                                        <button
                                            type="submit"
                                            className="text-blue-600 hover:text-white border border-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-blue-300 text-sm font-semibold rounded-lg text-md px-5 text-center me-2 mb-2 dark:border-blue-500 dark:hover:text-white dark:focus:ring-blue-800 flex items-center"
                                            disabled={isLoading}
                                        >
                                            {isLoading && (
                                                <svg
                                                    className="animate-spin h-5 w-5 mr-3 border-t-2 border-b-2 border-white"
                                                    viewBox="0 0 24 24"
                                                ></svg>
                                            )}
                                            {isLoading ? 'Adding...' : 'Add Agency'}
                                        </button>
                                        <button
                                            type="button"
                                            onClick={handleClose}
                                            className="text-red-700 hover:text-white border border-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-red-500 dark:text-red-500 dark:hover:text-white dark:hover:bg-red-600 dark:focus:ring-red-900"
                                        >
                                            Close
                                        </button>
                                    </div>
                                </form>
                            </div>
                        )}
                    </div>
                </div>
            </motion.div>
        </div>
    );
};

export default AddUser;


