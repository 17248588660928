import React, { useState, useEffect } from "react";
import axiosInstance from "../axiosInstance/axiosInstance";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

const UniversityAgent = ({
  universities,
  fetchUniversities,
  isMyUniversity,
  fetchMyUniversities,
  agencyUniversities,
  setAgencyUniversities,
  fetchAgencyUniversities,
  setIsMyUniversity,
}) => {
  const [selectedUniversityId, setSelectedUniversityId] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [confirmRemoveModalOpen, setConfirmRemoveModalOpen] = useState(false);
  const [showLimitModal, setShowLimitModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchAgencyUniversities();
    
  }, []);

  const openConfirmModal = (universityId) => {
    setSelectedUniversityId(universityId);
    setConfirmModalOpen(true);
  };

  const openConfirmRemoveModal = (universityId) => {
    setSelectedUniversityId(universityId);
    setConfirmRemoveModalOpen(true);
  };

  const handleConfirmRemoveUniversity = async () => {
    await handleRemoveUniversity(selectedUniversityId);
    setConfirmRemoveModalOpen(false);
  };

  const handleConfirmAddUniversity = async () => {
    await handleAddUniversity(selectedUniversityId);
    setConfirmModalOpen(false);
  };

  const handleCancel = () => {
    setConfirmModalOpen(false);
    setConfirmRemoveModalOpen(false);
    setShowLimitModal(false)
  };

  const handleRemoveUniversity = async (universityId) => {
    try {
      const response = await axiosInstance.post(
        "home/api/v1/university_unassign/",
        {
          university: universityId,
        }
      );
      console.log("University unassigned successfully:", response.data);
      fetchMyUniversities();
      fetchAgencyUniversities();
    } catch (error) {
      console.error("Error unassigning university:", error);
    }
  };

  const handleAddUniversity = async (universityId) => {
    try {
      const response = await axiosInstance.post(
        "home/api/v1/assign_university/",
        {
          university: universityId,
        }
      );
      console.log("University assigned successfully:", response.data);
      fetchUniversities();
      fetchMyUniversities();
      fetchAgencyUniversities();
    } catch (error) {
      console.error("Error assigning university:", error);
      if (error.response && error.response.status === 409) {
        setShowLimitModal(true); // Show the modal if the status is 409
      }
    }

  };

  // Check if a university is already in the agency universities list
  const isUniversityAssigned = (universityId) => {
    return agencyUniversities.some(
      (university) => university.id === universityId
    );
  };

  // const isAlluniversityAssigned = (universityId) => {
  //     return universities.some(university => university.id === universityId);
  // };

  const goToCourseDashboard = (universityId) => {
    navigate(`/AgentCourse/${universityId}`); // Navigate to CourseDashboard page with universityId as a URL param
  };

  return (
    <div>
      <div>
        <div className="flex flex-col gap-6">

          <div className="grid grid-row-1 md:grid-row-1 lg:grid-row-2  w-full ">
            {universities.map((university) => (
              // <div key={university.id} className="bg-white py-2.5  border-2" onClick={() => goToCourseDashboard(university.id)}>
                <div key={university.id} className="bg-white py-2.5  border-2" onClick={() => goToCourseDashboard(university.id)}>
                <div className="flex flex-col justify-between gap-2 ">
                  <div>
                    <div className="flex justify-between items-center px-4">
                      <div className='flex gap-4 jusctify-center items-center'>
                        <img src={university.logo_image} alt="University Logo" className="w-12 h-12  object-fit" />
                        <div>
                          <div className='flex items-center gap-2'>
                            <div className='text-[14px] pt-sans-bold '>{university.name}</div>
                            {university.is_norvel_recommended && (
                              <div className="pt-sans-bold text-[12px] bg-green-300 rounded-sm px-1 whitespace-nowrap">
                                Norvel Recommended
                              </div>
                            )}
                          </div>
                          {/* <div className='flex gap-1 items-center'>
                                                        <div className='text-[12px]'>
                                                            Fees Starts at:
                                                            <span className='text-[10px] pt-sans-bold'>
                                                                {university.minimum_fee_per_year} Lakhs per Year*
                                                            </span>
                                                        </div>
                                                        {university.language_requirements && university.language_requirements.length > 0 && (
                                                            <div>
                                                                <div className='text-[10px] mt-1 pt-sans-regular flex items-center'>
                                                                    <span className='pr-1'>/</span>
                                                                    {university.language_requirements.map((lang, idx) => (
                                                                        <span key={lang.id}>
                                                                            {lang.name}
                                                                            {idx < university.language_requirements.length - 1 ? "\u00A0/\u00A0" : ""}
                                                                        </span>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div> */}

                                        <div className='  flex  items-center pt-1 gap-2'>
                                           <div className='text-[13px]'>{university.country?.name}</div>
                                            
                                            <div className='text-[13px] pt-sans-bold'>{university.location}</div>
                                        </div>





                        </div>
                      </div>

                      <div className='hidden md:block '>
                        <div className="flex gap-1 items-center">
                          {/* <div className='pt-sans-bold text-[13px]'>{university.country?.name}</div>
                                                    <img src={university.country?.flag} alt="University Logo" className="w-8 h-8 rounded-full" /> */}

                          <div className='flex gap-4 justify-end '>
                            {isMyUniversity ? (
                              <div
                                className="text-[12px] underline pt-sans-bold  cursor-pointer text-red-600"
                                onClick={(event) => {
                                  event.stopPropagation(); // Prevent parent onClick
                                  openConfirmRemoveModal(university.id);
                                }}
                              >
                                Delete from list
                              </div>
                            ) : (
                              <div
                                className={`text-[12px] pt-sans-bold underline flex justify-end ${university.is_assigned_to_agency === true ? 'text-red-600' : 'text-green-600 cursor-pointer'
                                  }`}
                                onClick={(event) => {
                                  event.stopPropagation(); // Prevent parent onClick
                                  if (!isUniversityAssigned(university.id)) {
                                    openConfirmModal(university.id);
                                  }
                                }}
                              >
                                {university.is_assigned_to_agency === true ? "Unavailable" : "Add University"}
                              </div>


                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="border border-dotted border-gray-300 flex items-center justify-center"></div> */}
                  <div className='flex justify-between'>
                    {/* <div className='hidden md:block'>
                                            <div className='text-[12px]'>Location:</div>
                                            <div className='text-[12px] pt-sans-bold'>{university.location}</div>
                                        </div> */}
                    {/* <div className="border-dotted-b flex items-center justify-center block md:hidden"></div> */}
                    <div className="flex">
                      {/* <div className='px-6 flex gap-2 items-center'>
                                                <div>
                                                    <img src="./scholarships.png" className='w-7 h-7 object-contain' />
                                                </div>
                                                <div>
                                                    <div className='text-[13px] pt-sans-regular'>Diploma</div>
                                                    <div className='pt-sans-bold text-[12px]'>{university.education_levels_count?.Diploma}</div>
                                                </div>
                                            </div> */}
                      {/* <div className='px-6 flex gap-2 items-center'>
                                                <div>
                                                    <img src="./bachelors.png" className='w-6 h-6 object-contain' />
                                                </div>
                                                <div>
                                                    <div className='text-[13px] pt-sans-regular'>Bachelors</div>
                                                    <div className='pt-sans-bold text-[12px]'>{university.education_levels_count?.Bachelors}</div>
                                                </div>
                                            </div> */}
                      {/* <div className='px-6 flex gap-2 items-center'>
                                                <div>
                                                    <img src="./masters.png" className='w-6 h-6 object-contain' />
                                                </div>
                                                <div>
                                                    <div className='text-[13px] pt-sans-regular'>Masters</div>
                                                    <div className='pt-sans-bold text-[12px]'>{university.education_levels_count?.Masters}</div>
                                                </div>
                                            </div> */}
                      {/* <div className='px-6 flex gap-2 items-center'>
                                                <div>
                                                    <img src="./type.png" className='w-6 h-6 object-contain' />
                                                </div>
                                                <div>
                                                    <div className='text-[13px] pt-sans-regular'>Type</div>
                                                    <div className='pt-sans-bold text-[12px]'>{university.university_type}</div>
                                                </div>
                                            </div> */}
                      {/* <div className='px-6 flex gap-2 items-center'>
                                                <div>
                                                    <img src="./rank.png" className='w-6 h-6 object-contain' />
                                                </div>
                                                <div>
                                                    <div className='text-[13px] pt-sans-regular'>Global Ranking</div>
                                                    <div className='pt-sans-bold text-[12px]'>{university.rank}</div>
                                                </div>
                                            </div> */}
                    </div>
                  </div>
                  {/* <div className="border border-dotted border-gray-300 flex items-center justify-center"></div> */}
                  {/* <div className='flex gap-4 justify-end'>
                                        { isMyUniversity ? (
                                            <div
                                                className="text-[12px] underline pt-sans-bold flex justify-end cursor-pointer text-red-600"
                                                onClick={(event) => {
                                                    event.stopPropagation(); // Prevent parent onClick
                                                    openConfirmRemoveModal(university.id);
                                                }}
                                            >
                                                Remove University
                                            </div>
                                        ) : (
                                            <div
                                                className={`text-[12px] pt-sans-bold underline flex justify-end ${university.is_assigned_to_agency === true ? 'text-red-600' : 'text-green-600 cursor-pointer'
                                                    }`}
                                                onClick={(event) => {
                                                    event.stopPropagation(); // Prevent parent onClick
                                                    if (!isUniversityAssigned(university.id)) {
                                                        openConfirmModal(university.id);
                                                    }
                                                }}
                                            >
                                                 {university.is_assigned_to_agency === true ? "Notify when available" : "Add University"}
                                            </div>


                                         )} 
                                    </div> */}
                </div>
              </div>
            ))}
          </div>











        </div>
      </div>
      <Modal open={showLimitModal} onClose={handleCancel}>
        <Box
          sx={{
            width: 400,
            padding: 4,
            margin: "auto",
            marginTop: "15%",
            backgroundColor: "white",
            borderRadius: 2,
          }}
        >
          <p className="inter-light pb-6 mt-1">
            You can only add up to 5 universities.
          </p>
          <div className="flex gap-2 justify-end">
            <button
              onClick={handleCancel}
              className="bg-black text-white font-bold py-1 px-8 text-[13px] rounded-sm inter-light"
            >
              Close
            </button>
          </div>
        </Box>
      </Modal>


      {/* Confirm Modals for Adding/Removing University */}
      <Modal
        open={confirmModalOpen}
        onClose={handleCancel}
        aria-labelledby="confirm-modal-title"
        aria-describedby="confirm-modal-description"
      >
        <Box
          sx={{
            width: 400,
            padding: 4,
            margin: "auto",
            marginTop: "15%",
            backgroundColor: "white",
            borderRadius: 2,
          }}
        >
          <p className="inter-light pb-6 mt-1 ">
            Do you want to add this university?
          </p>
          <div className="flex gap-2 justify-end">
            <button
              onClick={handleCancel}
              className="bg-black text-white font-bold py-1 px-8 text-[13px] rounded-sm inter-light"
            >
              No
            </button>
            <button
              onClick={handleConfirmAddUniversity}
              className="bg-black text-white font-bold py-1 px-8 text-[13px] rounded-sm inter-light"
            >
              Yes
            </button>
          </div>
        </Box>
      </Modal>
      <Modal open={confirmRemoveModalOpen} onClose={handleCancel}>
        <Box
          sx={{
            width: 400,
            padding: 4,
            margin: "auto",
            marginTop: "15%",
            backgroundColor: "white",
            borderRadius: 2,
          }}
        >
          <p className="inter-light pb-6 mt-1 ">
            Do you want to remove this university?
          </p>
          <div className="flex gap-2 justify-end">
            <button
              onClick={handleCancel}
              className="bg-black text-white font-bold py-1 px-8 text-[13px] rounded-sm"
            >
              No
            </button>
            <button
              onClick={handleConfirmRemoveUniversity}
              className="bg-black text-white font-bold py-1 px-8 text-[13px] rounded-sm"
            >
              Yes
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default UniversityAgent;
