const CoursePage = ({ courses }) => {
    const safeCourses = Array.isArray(courses) ? courses : [];

    return (
        <div>
            <div>
                <div className=' '>
                    <div className=" flex flex-col gap-2 ">
                        {
                            safeCourses.map(course => (
                                <div key={course.id} className="bg-white shadow-md flex flex-col gap-2 ">
                                    <div className="flex flex-col justify-between gap-4 px-4 py-4">
                                        <div className="flex flex-col md:flex-row justify-between">
                                            <div>
                                                <span className='pt-sans-regular text-[12px] md:hidden'>sydney</span>
                                                <span className='pt-sans-regular px-2 text-[10px] md:hidden'>{course.country?.name}</span>
                                                <div className='flex items-center gap-2'>
                                                    <div className='pt-sans-bold text-xl'>{course.name}</div>
                                                    {course.
                                                        is_norvel_recommended &&
                                                        <div className=' hidden md:block pt-sans-bold text-[10px] bg-green-500 rounded-sm px-1'> Recommended</div>}
                                                </div>
                                                <div className='pt-sans-regular text-[12px] flex gap-1 flex-wrap'>
                                                    {course.education_level} / {course.course_duration} Years /
                                                    <span className='pt-sans-bold'> {course.fee_per_year} Lakhs per year* / </span>
                                                    <span className='flex gap-1 items-center'>
                                                        {/* Check if language_requirements is an array before mapping */}
                                                        {Array.isArray(course.language_requirements) && course.language_requirements.length > 0 ? (
                                                            <>
                                                                {course.language_requirements.map((requirement, index) => (
                                                                    <div className='flex' key={index}>
                                                                        <span className='pt-sans-regular text-[11px] flex'>
                                                                            {/* Language requirement and score */}
                                                                            {requirement.language_requirement}
                                                                            {/* Only display '-' and score if the requirement is not 'MOI' */}
                                                                            {requirement.language_requirement !== 'MOI' && ` - ${requirement.score}`}
                                                                            {/* Add '/' unless it is the last item */}
                                                                            {index < course.language_requirements.length - 1 && ' / '}
                                                                        </span>
                                                                    </div>
                                                                ))}
                                                                {/* Add "required" after the entire list */}
                                                                <span className="pt-sans-regular text-[11px] flex"> required</span>
                                                            </>
                                                        ) : (
                                                            'N/A' // Fallback if language_requirements is empty or undefined
                                                        )}
                                                    </span>

                                                </div>





                                            </div>
                                            <div className='pt-4   md:block  hidden'>
                                                <div>
                                                    <div className='pt-sans-bold text-[11px]'>Living Expense : <span className='pt-sans-regular'>{course.POF}Lakhs* / Year</span> </div>
                                                    <div className='text-[10px]'>{course.POF_mode}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class=" border border-dotted-b   flex items-center justify-center "></div>
                                        <div>
                                            <div className="flex  justify-between item-center">
                                                <div className='flex items-center gap-4'>
                                                    <img src={course.university.logo_image} alt={`${course.university.name} Logo`} className="w-12 h-12 object-cover rounded-full" /> {/* Adjust width and height as needed */}
                                                    <div>
                                                        <div className='text-[12px] pt-sans-bold '>{course.university?.name}</div>
                                                        <div className=' hidden md:flex gap-2 ' >

                                                            <div className='text-[12px]'><span className='pt-sans-bold'>private</span></div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className='hidden md:block'>
                                                    <div className="flex gap-1  items-center ">
                                                        <div className='pt-sans-bold text-[12px]'>{course.campus} , {course.country?.name}</div>
                                                        <img src={course.country?.flag} alt={`${course.country?.name} Logo`} className="w-8 h-8 object-contain rounded-full" /> {/* Adjust width and height as needed */}

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class=" border border-dotted-b   flex items-center justify-center"></div>
                                        <div className="flex justify-between">
                                            <div className="flex gap-2 flex-wrap ">
                                                {course.tags.map(tag => (
                                                    <div key={tag.id}>
                                                        <span className='inter-bold text-[10px] bg-green-100 px-4 rounded-full py-2'>{tag.tag}</span>
                                                    </div>
                                                ))}
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CoursePage