import React, { useState,useEffect } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import CountryDropdown from './countryDropown';
import axiosInstance from '../axiosInstance/axiosInstance';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
const UniversiyAgentSearch = ({ toggleAddCountryModal, toggleAddUniversityModal,toggleaddmoney, onSearchCountry, onSearchUniversity,searchCountry,searchUniversity }) => {
    
    const [countryOptions, setCountryOptions] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState({ id: '', name: '' });
    const [countryDropdownOpen, setCountryDropdownOpen] = useState(false);
    const [walletAmount, setWalletAmount] = useState(null); 

    useEffect(() => {
        
        const fetchCountries = async () => {
            try {
                const response = await axiosInstance.get('/kanban/api/v1/country_dropdown/');
                setCountryOptions(response.data); 
            } catch (error) {
                console.error('Error fetching country dropdown:', error);
            }
        };
        fetchCountries();
    }, []);

    useEffect(() => {
        fetchWalletAmount(); 
    }, []);
    const navigate = useNavigate();
    const handleBalanceClick = () => {
        navigate('/balance');
    };
    const fetchWalletAmount = async () => {
        try {
            const response = await axiosInstance.get('account/api/v1/agency-wallet-detail-view/');
            setWalletAmount(response.data.amount
            );
            console.log(response,"wallent amount");
            
        } catch (error) {
            console.error('Error fetching wallet amount:', error);
        }
    };

    const toggleCountryDropdown = () => {
        setCountryDropdownOpen(!countryDropdownOpen); 
    };

    const handleCountryChange = (e) => {
        const value = e.target.value;
       
        onSearchCountry(value); 
    };

    const handleUniversityChange = (e) => {
        const value = e.target.value;
       
        onSearchUniversity(value); 
    };

    const handleCountrySelect = (country) => {
        setSelectedCountry({ id: country.id, name: country.name });
        onSearchCountry(country.id);  // Pass the country ID to the parent component
    };
     const location = useLocation();
    return (
        <div className='flex justify-between'>
            <div className='flex gap-6'>
                <div className="relative">

                     <CountryDropdown
                    options={countryOptions}          
                    selectedOption={selectedCountry}  
                    onSelect={handleCountrySelect}    
                    toggleDropdown={toggleCountryDropdown}  
                    dropdownOpen={countryDropdownOpen}     
                    setDropdownOpen={setCountryDropdownOpen} 
                />
                </div>
                

                <div className="relative">

                    <input
                        type="text"
                        value={searchUniversity}
                        onChange={handleUniversityChange}
                        className="inter-font block w-96 text-[11px] rounded-md pr-12 pl-10 pr-3 py-4 bg-gray-100 border-gray-200 border-none placeholder-gray-400 focus:outline-none tracking-wide"
                        placeholder="Search University here..."
                    />
                    <span className="absolute inset-y-0 right-0 px-4 text-gray-400 text-sm flex items-center">
                        <SearchIcon />
                    </span>
                </div>

            </div>

                <div>
                {location.pathname ==='/UniversityAgent' &&(
               <div className='class="flex items-center space-x-4'>
                {/* {!toggleaddmoney&&(<button class="px-6 py-2 bg-green-200 text-black border-2 border-black font-semibold  hover:ring-2 hover:ring-blue-500">
              Add Money
                </button>)} */}
{/*                 
                <button class="px-6 py-2 bg-green-200 text-black border-2 border-black font-semibold  hover:ring-2 hover:ring-blue-500">
              Add Money
                </button> */}
               
                 <button class="px-10 py-2  text-black border-2 border-black font-semibold  hover:ring-2 hover:ring-blue-500" onClick={handleBalanceClick}>
                 Balance {walletAmount}
                    </button>
               </div>
                )}
                </div>



        </div>
    )
}

export default UniversiyAgentSearch