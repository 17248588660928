import React, { useState,useEffect } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import CountryDropdown from './countryDropown';
import axiosInstance from '../axiosInstance/axiosInstance';

const UniversiySearchBar = ({ toggleAddCountryModal, toggleAddUniversityModal, onSearchCountry, onSearchUniversity,searchCountry,searchUniversity }) => {
    
    const [countryOptions, setCountryOptions] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState({ id: '', name: '' });
    const [countryDropdownOpen, setCountryDropdownOpen] = useState(false);


    useEffect(() => {
        
        const fetchCountries = async () => {
            try {
                const response = await axiosInstance.get('/kanban/api/v1/country_dropdown/');
                setCountryOptions(response.data); 
            } catch (error) {
                console.error('Error fetching country dropdown:', error);
            }
        };
        fetchCountries();
    }, []);
    const toggleCountryDropdown = () => {
        setCountryDropdownOpen(!countryDropdownOpen); 
    };

    const handleCountryChange = (e) => {
        const value = e.target.value;
       
        onSearchCountry(value); 
    };

    const handleUniversityChange = (e) => {
        const value = e.target.value;
       
        onSearchUniversity(value); 
    };

    const handleCountrySelect = (country) => {
        setSelectedCountry({ id: country.id, name: country.name });
        onSearchCountry(country.id);  // Pass the country ID to the parent component
    };
    return (
        <div>
            <div className='flex justify-between'>
                <div className='flex gap-6' >

                <div className="relative">

                     <CountryDropdown
                    options={countryOptions}          
                    selectedOption={selectedCountry}  
                    onSelect={handleCountrySelect}    
                    toggleDropdown={toggleCountryDropdown}  
                    dropdownOpen={countryDropdownOpen}     
                    setDropdownOpen={setCountryDropdownOpen} 
                />
                </div>
                <div className="relative">

                    <input
                        type="text"
                        value={searchUniversity}
                        onChange={handleUniversityChange}
                        className="inter-font block w-96 text-[11px] rounded-md pr-12 pl-10 pr-3 py-4 bg-gray-100 border-gray-200 border-none placeholder-gray-400 focus:outline-none tracking-wide"
                        placeholder="Search University here..."
                    />
                    <span className="absolute inset-y-0 right-0 bottom-3 px-4 text-gray-400 text-sm flex items-center">
                        <SearchIcon />
                    </span>
                </div>
                </div>
                <div className='flex flex-row gap-4'>

                <div>
                    <button className="bg-green-400 px-6 py-3  text-black   font-semibold " onClick={toggleAddUniversityModal}>
                        Add University
                    </button>

                </div>
                <div>
                    <button className="border border-1   border-black px-6 py-2.5  text-black  font-semibold " onClick={toggleAddCountryModal}>
                        Create Country
                    </button>

                </div>
                </div>


            </div>



        </div>
    )
}

export default UniversiySearchBar