import React, { useState } from 'react';
import axiosInstance from '../axiosInstance/axiosInstance';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import GroupPNG from '../../Assets/Group.png';


const ResetPassword = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { username, oldpassword } = location.state;

    const [formData, setFormData] = useState({
        username: username,
        oldpassword: oldpassword,
        new_password: '',

    });
    const [errorMessage, setErrorMessage] = useState('');

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {

            if (formData.new_password !== formData.confirmpassword) {
                setErrorMessage('Passwords do not match');
                return;
            }

            const resetPayload = {
                "username": username,
                "password": oldpassword,
                "new_password": formData.confirmpassword
            }
            const response = await axiosInstance.post('account/api/reset-password/', resetPayload);
            if (response && response.data) {

                console.log('Password reset successful');

                navigate('/login');
            } else {
                console.error('Password reset failed: Response data is missing');
            }
        } catch (error) {
            console.error('Password reset failed:', error.message);
            setErrorMessage('Something went wrong. Please try again.');
        }
    };

    return (
        <div className='flex justify-center items-center h-[100vh]'>

            <div className="bg-white px-8  rounded shadow-md w-full  sm:w-96">

                <div className='py-6'>
                    <div className="flex justify-between items-center ">
                        <div className='flex gap-2'>
                            <div className="w-[20px] overflow-hidden">
                                <img
                                    className="w-full h-full object-contain"
                                    src={GroupPNG}
                                    alt="logo"
                                />
                            </div>
                            <div className="font-bold text-xl text-black ">norvel</div>

                        </div>

                        <button
                            onClick={() => navigate('/login')}
                            className=' text-[15px] flex items-center justify-center bg-gray-100 rounded-full w-6 h-6'>
                            <CloseIcon fontSize='small' />
                        </button>
                    </div>

                </div>
                <div className="flex justify-center items-center flex-col gap-4">
                    <h1 className="text-2xl font-bold ">Password Reset</h1>
                </div>
                <div className='py-8'>
                    <form onSubmit={handleSubmit} >
                        <div className="mb-4">
                            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                New Password
                            </label>
                            <input
                                type="password"
                                id="new_password"
                                name="new_password"
                                value={formData.new_password}
                                onChange={handleChange}
                                className="mt-1 block w-full px-2 border-gray-700 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 border py-1.5"
                            />
                        </div>
                        <div className="mb-6">
                            <label htmlFor="confirm-password" className="block text-sm font-medium text-gray-700">
                                Confirm Password
                            </label>
                            <input
                                type="password"
                                id="confirmpassword"
                                name="confirmpassword"
                                value={formData.confirmpassword}
                                onChange={handleChange}
                                className="mt-1 block px-2 w-full border-gray-700 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 border py-1.5"
                            />
                        </div>
                        {errorMessage && <p className="text-red-500 text-[14px]">{errorMessage}</p>}
                        <button
                            type="submit"
                            className="w-full font-semibold bg-green-500 px-2 text-white py-2 px-4 rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-200"
                        >
                            Reset Password
                        </button>
                    </form>

                </div>


            </div>
        </div>
    );
};

export default ResetPassword;

