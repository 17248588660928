

import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { ReactComponent as CountrySVG } from '../../Assets/country.svg';
import { ReactComponent as SettingsSVG } from '../../Assets/settings.svg';
import { ReactComponent as CourseSVG } from '../../Assets/course.svg';
import { ReactComponent as ClientsSVG } from '../../Assets/clients.svg';
import { ReactComponent as DashboardSVG } from '../../Assets/dashboard.svg';
import { ReactComponent as CourseagentSVG } from '../../Assets/courseagent.svg';
import GroupPNG from '../../Assets/Group.png'; // Correct way to import PNG

import axiosInstance from '../axiosInstance/axiosInstance';

const SideBar = ({ toggleUserSideVisibility, toggleAgentSideVisibility }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const userFromLocalStorage = JSON.parse(localStorage.getItem('user_info'));
  const isUserSideVisible = localStorage.getItem('isUserSideVisible') === 'true';
  const [menus, setMenu] = useState(false);
  const [error, setError] = useState(null);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const updateScreenWidth = () => setScreenWidth(window.innerWidth);

  const handleIconClick = () => {
    navigate("/userside");
  };

  const handleIconBack = () => {
    navigate("/dashboard");
  };

  const handleAgentStaff = () => {
    navigate("/agentstaffside");
  };

  const handleSource = () => {
    navigate("/sourceDashboard");
  };

  const handleCourse = () => {
    navigate("/university");
  };

  const handleCountry = () => {
    navigate("/countrydashboard");
  };

  const handleAgent = () => {
    navigate("/UniversityAgent");
  };

  useEffect(() => {
    const fetchIcons = async () => {
      try {
        const response = await axiosInstance.get('account/api/v1/module_menus/');
        setMenu(response.data);
      } catch (error) {
        console.error('Error fetching icons:', error);
        setError(error);
      }
    };

    fetchIcons();
    window.addEventListener('resize', updateScreenWidth);
    return () => window.removeEventListener('resize', updateScreenWidth);
  }, []);
  const isMobileView = screenWidth < 1024;

  // Check for AgentCourse dynamic route using startsWith
  const isAgentCoursePath = location.pathname.startsWith('/AgentCourse');
  const userInfoString = localStorage.getItem('user_info');
  const userInfo = JSON.parse(userInfoString);
  
  const logout = async () => {
    const refreshToken = localStorage.getItem('refreshToken');

    if (!refreshToken) {
        console.error('No refresh token found');
        return;
    }

    try {
        const response = await axiosInstance.post('/account/api/logout/', {
            refresh: refreshToken,
        });

        if (response.status === 200) {
            localStorage.removeItem('token');
            localStorage.removeItem('refreshToken');
            console.log('Logged out successfully');
            navigate('/login');
        } else {
            console.error('Failed to logout:', response.status, response.data);
        }
    } catch (error) {
        if (error.response && error.response.status === 400) {
            localStorage.removeItem('token');
            localStorage.removeItem('refreshToken');
            navigate('/login');
        } else {
            console.error('Error during logout:', error);
        }
    }
};
const handleProfile = () => {
  navigate('/profile');
};






  return (
    <div className="h-full relative  w-[179px] " style={{ height: '95vh' }}>

      {/* {(location.pathname === '/university' ||
        location.pathname === '/UniversityCourse' ||
        location.pathname === '/countrydashboard' ||
        location.pathname === '/UniversityAgent' ||
        isAgentCoursePath) && (
          <div className=''>
            <div className='flex items-center gap-2 pb-6 justify-center '>
              <div className='w-6 h-6 ml-2 overflow-hidden flex justify-center items-center'>
                {/* <img className='w-full h-full object-cover' src="./Group.png" alt="logo" /> */}
      {/* <div className='w-6 h-6  overflow-hidden flex justify-center items-center'>
                  <img className='w-full h-full object-cover' src={GroupPNG} alt="logo" />
                </div>

              </div>
              <div>
                <div className='text-3xl font-bold'>norvel</div>
              </div>
            </div>
          </div>
        )} */}
      {location.pathname !== '/profile' && (
        <div className=''>
          <div className='flex items-center gap-2 pb-6 justify-center mx-6'>
            <div className='w-6 h-6  overflow-hidden flex justify-center items-center'>
              {/* <img className='w-full h-full object-cover' src="./Group.png" alt="logo" /> */}
              <div className='w-6 h-6  overflow-hidden flex justify-center items-center'>
                <img className='w-full h-full object-cover' src={GroupPNG} alt="logo" />
              </div>

            </div>
            <div>
              <div className="poppins-extrabold text-2xl xl:text-3xl ">norvel</div>
            </div>
          </div>
        </div>

      )}


      <div className="flex flex-col  h-full py-4 ">
        <div className="flex flex-col gap-6  flex-grow">
          <div className='flex gap-2 items-center px-8   '>
            <div className="text-green-500 text-xl cursor-pointer ">
              <DashboardSVG />
            </div>
            {!isMobileView && <div className="text-[12px] text-gray-600 pt-sans-bold ">Dashboard</div>}
            {/* <div className='text-[12px] text-gray-600 pt-sans-bold'>Dashboard</div> */}
          </div>

          <div className={`${location.pathname === "/dashboard" ? 'bg-green-400 py-2.5 ' : ''}`}>
            <div className='flex gap-2 items-center px-8 cursor-pointer' onClick={handleIconBack}>
              <div className="text-gray-500 text-xl">
                {location.pathname === "/dashboard" ? <ClientsSVG /> : <ClientsSVG />}
              </div>
              {/* <div className='text-[12px] text-gray-600 pt-sans-bold'>Clients</div> */}
              {!isMobileView && <div className="text-[12px] text-gray-600 pt-sans-bold">Clients</div>}
            </div>
          </div>

          {menus && menus.consultant && (
            <div className={`${location.pathname === "/university" ? 'bg-green-400 py-2' : ''}`}>
              <div className='flex gap-2 items-center px-8 cursor-pointer' onClick={handleCourse}>
                <div className="text-gray-500 text-xl">
                  {location.pathname === "/university" ? <CourseSVG /> : <CourseSVG />}
                </div>
                {/* <div className='text-[12px] text-gray-600 pt-sans-bold'>Course</div> */}
                {!isMobileView && <div className="text-[12px] text-gray-600 pt-sans-bold">Course</div>}

              </div>
            </div>
          )}

          {menus && menus.add_staff && (
            <div className={`${location.pathname === "/UniversityAgent" ? 'bg-green-400 py-2' : ''}`}>
              <div className='flex gap-2 items-center px-8 cursor-pointer' onClick={handleAgent}>
                <div className="text-gray-500 text-xl">
                  {location.pathname === "/university" ? <CourseagentSVG /> : <CourseagentSVG />}
                </div>
                {/* <div className='text-[12px] text-gray-600 pt-sans-bold'>Course</div> */}
                {!isMobileView && <div className="text-[12px] text-gray-600 pt-sans-bold">Course</div>}
              </div>
            </div>
          )}

          {menus && menus.consultant && (
            <div className={`${location.pathname === "/countrydashboard" ? 'bg-green-400 py-2' : ''}`}>
              <div className='flex gap-2 items-center cursor-pointer px-8' onClick={handleCountry}>
                <div className="text-gray-500 text-xl cursor-pointer">
                  {location.pathname === "/countrydashboard" ? <CountrySVG /> : <CountrySVG />}
                </div>
                {/* <div className='text-[12px] text-gray-600 pt-sans-bold'>Country</div> */}
                {!isMobileView && <div className="text-[12px] text-gray-600 pt-sans-bold">Country</div>}
              </div>
            </div>
          )}

          {menus && menus.consultant && (
            <div className={`${location.pathname === "/userside" ? 'bg-green-400 py-2' : ''}`}>
              <div className='flex gap-2 items-center cursor-pointer px-8' onClick={handleIconClick}>
                <div className="text-gray-500 text-xl">
                  {location.pathname === "/userside" ? <SettingsSVG fill="blue" /> : <SettingsSVG fill="blue" />}
                </div>
                {/* <div className='text-[12px] text-gray-600 pt-sans-bold'>Settings</div> */}
                {!isMobileView && <div className="text-[12px] text-gray-600 pt-sans-bold">Settings</div>}
              </div>
            </div>
          )}

          {menus && menus.add_staff && (
            <div className={`${location.pathname === "/agentstaffside" ? 'bg-green-400 py-2' : ''}`} onClick={handleAgentStaff}>
              <div className='flex gap-2 px-8 items-center cursor-pointer'>
                <div className="text-gray-500 text-xl ">
                  {(location.pathname === "/agentstaffside") || (location.pathname === "/sourceDashboard")
                    ? <SettingsSVG fill="blue" />
                    : <SettingsSVG fill="blue" />}
                </div>
                {/* <div className='text-[12px] text-gray-600 pt-sans-bold'>Settings</div> */}
                {!isMobileView && <div className="text-[12px] text-gray-600 pt-sans-bold">Settings</div>}
              </div>
            </div>
          )}
           </div>
        <div className='bg-green-50 absolute bottom-0'>
        <div className='flex flex-col gap-2 py-4 px-3 items-center justify-center  '>
            <div className='flex items-center cursor-pointer ' >
              <div className="w-10 h-10 ml-2">
                <img className="w-full h-full rounded-full" src="/profile.jpg" alt="Profile" />
              </div>
              {/* <div>
                            <KeyboardArrowDownIcon style={{ fontSize: '20px', color: 'gray' }} />
                        </div> */}
            </div>
            <div className="flex flex-col items-center">
              <span className="inter-font text-[12px] font-semibold text-black opacity-80">
                {userInfo && userInfo.first_name ? userInfo.first_name : userInfo && userInfo.username}
              </span>
              <span className="inter-font text-[11px] font-semibold text-gray-400">
                Admin
              </span>
            </div>
            <div>
              <button
                type="button"
                onClick={logout}
               
                className="pt-sans-bold  border border-green-500 text-green-500 text-[12px]  px-14 py-2  hover:bg-green-700 hover:text-white tracking-wider"
              >
              LOGOUT
              </button>
            </div>
            {menus && menus.add_staff && (
            <div>
              <button
                type="button"
                onClick={handleProfile}
               
                className="pt-sans-bold  border border-green-500 text-green-500 text-[12px]  px-14 py-2  hover:bg-green-700 hover:text-white tracking-wider"
              >
              PROFILE
              </button>
            </div>
            )}

          </div>

        </div>
       
      </div>
    </div>
  );
};


export default SideBar;




