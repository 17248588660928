import React, { useEffect, useRef, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import DatePicker from 'react-datepicker';
import { motion } from 'framer-motion';
import axiosInstance from '../axiosInstance/axiosInstance';
import UniversityDropdown from './UniversityDropdown';
import CountryDropdown from './CountryDropdown';

const UpdateUniversity = ({ currentPage,itemsPerPage, universityId, onClose, setIsUpdateUniversityVisible, fetchUniversities }) => {
    const bannerFileInputRef = useRef(null);
    const logoFileInputRef = useRef(null);
    const [bannerFileName, setBannerFileName] = useState('No file chosen');
    const [logoFileName, setLogoFileName] = useState('No file chosen');
    const [startDate, setStartDate] = useState(null);
    const [studentReviewUrls, setStudentReviewUrls] = useState([{ id: Date.now() }]);
    const [universityTourVideos, setUniversityTourVideos] = useState([{ id: Date.now() }]);
    const [campusLocations, setCampusLocations] = useState('');
    const [universityName, setUniversityName] = useState('');
    const [bannerImageUrl, setBannerImageUrl] = useState('');
    const [logoUrl, setLogoUrl] = useState('');
    const [pofAmount, setPofAmount] = useState('');
    const [language, setLanguage] = useState('');
    const [mark, setMark] = useState('');
    const [rank, setRank] = useState('');
    const [universityOpen, setUniversityOpen] = useState(null);
    const [universityType, setUniversityType] = useState('')
    const [isNorvelRecommended, setIsNorvelRecommended] = useState('');
    const [countryDropOptions, setCountryDropOptions] = useState([]);

    const [countryOpen, setCountryOpen] = useState(null);
    const [countryType, setCountryType] = useState('')
    const [countryId, setCountryId] = useState('');
    useEffect(() => {
        const fetchCountry = async () => {
            try {
                const response = await axiosInstance.get('kanban/api/v1/country_dropdown/');
                if (response.status === 200) {
                    setCountryDropOptions(response.data); // Use the data as is
                } else {
                    console.error('Failed to fetch education levels');
                }
            } catch (error) {
                console.error('Error fetching education levels:', error);
            }
        };

        fetchCountry();
    }, []);
    const toggleCountry = () => {
        setCountryOpen((prevState) => !prevState);
    };
    const CountryMethod = (option) => {
        setCountryType(option.name);
        setCountryId(option.id);
    };


    useEffect(() => {

        axiosInstance.get(`home/api/v1/university_detail/${universityId}/`)
            .then(response => {
                const universityData = response.data;
                setUniversityName(universityData.name);
                setCampusLocations(universityData.location);
                setMark(universityData.minimum_mark);
                setPofAmount(universityData.pof_amount);
                setBannerImageUrl(universityData.banner_image || '');
                setLogoUrl(universityData.logo_image || '');
                setLanguage(universityData.language_pref);
                setStartDate(universityData.intake);
                setUniversityType(universityData.university_type);
                setRank(universityData.rank)
                setCountryType(universityData.country.name)
                setIsNorvelRecommended(universityData.is_norvel_recommended ? 'true' : 'false');



                const parsedReviewUrls = JSON.parse(universityData.review_video_url[0].video_url || '[]');
                const parsedTourVideos = JSON.parse(universityData.tour_video_url[0].video_url || '[]');


                setStudentReviewUrls(parsedReviewUrls.map((url, index) => ({ id: `review-${index}`, url })));
                setUniversityTourVideos(parsedTourVideos.map((url, index) => ({ id: `tour-${index}`, url })));
            })
            .catch(error => {
                console.error("Error fetching university details:", error);

            });
    }, [universityId]);
    const handleInputChange = (index, setFunction) => (event) => {
        const newUrls = [...setFunction];
        newUrls[index].url = event.target.value;
        setFunction(newUrls);
    };

    const addNewUrlField = (setFunction) => () => {
        setFunction(prevState => [...prevState, { id: Date.now(), url: '' }]);
    };

    const handleImageButtonClick = (inputRef) => () => {
        inputRef.current.value = null;
        inputRef.current.click();
    };

    const handleBannerImageChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            setBannerFileName(file.name);
            const fileUrl = await uploadImage(file);
            if (fileUrl) {
                setBannerImageUrl(fileUrl);
            }
        }
    };

    const handleLogoImageChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            setLogoFileName(file.name);
            const fileUrl = await uploadImage(file);
            if (fileUrl) {
                setLogoUrl(fileUrl);
            }
        }
    };


    const uploadImage = async (file) => {
        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await axiosInstance.post('/home/api/v1/upload-file/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            return response.data.image_url
                ;
        } catch (error) {
            console.error('Error uploading image:', error);
            return null;
        }
    };


    const addStudentReviewField = () => {
        setStudentReviewUrls([...studentReviewUrls, { id: Date.now() }]);
    };

    const addUniversityTourField = () => {
        setUniversityTourVideos([...universityTourVideos, { id: Date.now() }]);
    };

    const formatDate = (date) => {
        if (!date || !(date instanceof Date) || isNaN(date.getTime())) {
            return '';
        }
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };


    const handleSubmit = async () => {
        const formData = new FormData();
        formData.append('name', universityName);
        formData.append('location', campusLocations);
        const pofAmountNumber = parseFloat(pofAmount) || 0;
        formData.append('pof_amount', pofAmountNumber);
        formData.append('language_pref', language);
        formData.append('minimum_mark', mark);
        formData.append('rank', rank);
        
        formData.append('intake', startDate);
        formData.append('university_type', universityType);
        formData.append('banner_image', bannerImageUrl);
        formData.append('logo_image', logoUrl);
        formData.append('is_norvel_recommended', isNorvelRecommended === 'true');
        formData.append('review_video_url', JSON.stringify(studentReviewUrls.map((url) => url.url || '')));
        formData.append('tour_video_url', JSON.stringify(universityTourVideos.map((video) => video.url || '')));
        try {
            const response = await axiosInstance.patch(`home/api/v1/university_detail/${universityId}/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });


            if (response.status === 200) {
                const offset = (currentPage - 1) * itemsPerPage; // Preserve the current pagination
                await fetchUniversities( offset); // Fetch universities with correct pagination
                // Call fetchUniversities after successful update

                setIsUpdateUniversityVisible(false);
            }
        } catch (error) {
            console.error('Error updating university:', error);

        }
    };

    const hidden = () => {
        setIsUpdateUniversityVisible(false);
    };
    const UniversityMethod = (option) => {
        setUniversityType(option);

    };

    const toggleUniversity = () => {
        setUniversityOpen((prevState) => !prevState);
    };

    return (
        <div>
            <motion.div
                className="fixed inset-0 bg-black bg-opacity-70"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                onClick={onClose}
            />
            <motion.div
                className="p-6 z-10"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
            >
                <div className="flex items-center justify-center absolute right-[400px] top-4">
                    <div className="shadow-xl rounded-md bg-white">
                        <div className="bg-black flex items-center justify-center">
                            <span className="text-2xl font-bold px-6 py-6 text-white">
                                Update University
                            </span>
                        </div>
                        <div className="px-4 py-4 pt-8">
                            <div className="flex flex-col md:flex-row justify-between gap-8 pb-2">
                                <div className="mb-4">
                                    <label
                                        htmlFor="university-name"
                                        className="block text-gray-700 text-[12px] text-gray-600 mb-2 inter-semilight"
                                    >
                                        University Name
                                    </label>
                                    <input
                                        id="university-name"
                                        type="text"
                                        className="border border-gray-300 rounded-md w-full md:w-[200px] lg:w-[300px] text-[12px] py-2 px-4 text-gray-700 leading-tight outline-none"
                                        placeholder="Enter text"
                                        value={universityName}
                                        onChange={(e) => setUniversityName(e.target.value)}
                                    />
                                </div>
                                <div className='mb-4'>
                                    <label
                                        htmlFor="university-name"
                                        className="block text-gray-700 text-[12px] text-gray-600 mb-2 inter-semilight"
                                    >
                                        Country
                                    </label>
                                    <CountryDropdown
                                        options={countryDropOptions}
                                        selectedOption={countryType}
                                        onSelect={CountryMethod}
                                        toggleDropdown={toggleCountry}
                                        countryOpen={countryOpen}
                                        setCountryOpen={setCountryOpen}





                                    />



                                </div>

                            </div>
                           
                            <div className="flex flex-col md:flex-row justify-between gap-2 pb-2">
                                <div className="mb-4">
                                    <label
                                        htmlFor="banner-image-url"
                                        className="block text-gray-700 text-[12px] text-gray-600 mb-2 inter-semilight"
                                    >
                                        University Banner URL
                                    </label>
                                    <input
                                        id="banner-image-url"
                                        type="text"
                                        value={bannerImageUrl}
                                        onChange={(e) => setBannerImageUrl(e.target.value)}
                                        className="border border-gray-300 rounded-md w-full md:w-[200px] lg:w-[300px] text-[12px] py-2 px-4 text-gray-700 leading-tight outline-none"
                                    />
                                </div>
                                <div className="mb-4">
                                    <label
                                        htmlFor="logo-image-url"
                                        className="block text-gray-700 text-[12px] text-gray-600 mb-2 inter-semilight"
                                    >
                                        University Logo URL
                                    </label>
                                    <input
                                        id="logo-image-url"
                                        type="text"
                                        value={logoUrl}
                                        onChange={(e) => setLogoUrl(e.target.value)}
                                        className="border border-gray-300 rounded-md w-full md:w-[200px] lg:w-[300px] text-[12px] py-2 px-4 text-gray-700 leading-tight outline-none"
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col md:flex-row justify-between gap-2 pb-2">
                                <div className="mb-4">
                                    <button
                                        type="button"
                                        onClick={handleImageButtonClick(bannerFileInputRef)}
                                        className="bg-black text-white w-full md:w-[200px] lg:w-[300px] py-2 px-4 rounded-md text-[12px]"
                                    >
                                        Upload Banner Image
                                    </button>
                                    <input
                                        ref={bannerFileInputRef}
                                        type="file"
                                        accept="image/*"
                                        onChange={handleBannerImageChange}
                                        style={{ display: 'none' }}
                                    />
                                    <div className="mt-2 text-gray-700 text-[12px]">
                                        {bannerFileName}
                                    </div>
                                    <div className="text-gray-500 text-[12px]">
                                    File size should not exceed 1MB.
                                    </div>
                                </div>
                                <div className="mb-4">
                                    <button
                                        type="button"
                                        onClick={handleImageButtonClick(logoFileInputRef)}
                                        className="bg-black text-white w-full md:w-[200px] lg:w-[300px] py-2 px-4 rounded-md text-[12px]"
                                    >
                                        Upload Logo Image
                                    </button>
                                    <input
                                        ref={logoFileInputRef}
                                        type="file"
                                        accept="image/*"
                                        onChange={handleLogoImageChange}
                                        style={{ display: 'none' }}
                                    />
                                    <div className="mt-2 text-gray-700 text-[12px]">
                                        {logoFileName}
                                    </div>
                                    <div className="text-gray-500 text-[12px]">
                                    File size should not exceed 1MB.
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col md:flex-row justify-between gap-2 pb-2">
                                <div className="mb-4">
                                    <label
                                        htmlFor="language-preference"
                                        className="block text-gray-700 text-[12px] text-gray-600 mb-2 inter-semilight"
                                    >
                                        Language Preference
                                    </label>
                                    <input
                                        id="language-preference"
                                        type="text"
                                        value={language}
                                        onChange={(e) => setLanguage(e.target.value)}
                                        className="border border-gray-300 rounded-md w-full md:w-[200px] lg:w-[300px] text-[12px] py-2 px-4 text-gray-700 leading-tight outline-none"
                                    />
                                </div>
                                <div className="mb-4">
                                    <label
                                        htmlFor="minimum-mark"
                                        className="block text-gray-700 text-[12px] text-gray-600 mb-2 inter-semilight"
                                    >
                                        Minimum Mark
                                    </label>
                                    <input
                                        id="minimum-mark"
                                        type="number"
                                        value={mark}
                                        onChange={(e) => setMark(e.target.value)}
                                        className="border border-gray-300 rounded-md w-full md:w-[200px] lg:w-[300px] text-[12px] py-2 px-4 text-gray-700 leading-tight outline-none"
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col md:flex-row justify-between gap-2 pb-2">
                                <div className="mb-4">
                                    <label
                                        htmlFor="pof-amount"
                                        className="block text-gray-700 text-[12px] text-gray-600 mb-2 inter-semilight"
                                    >
                                        POF Amount
                                    </label>
                                    <input
                                        id="pof-amount"
                                        type="number"
                                        value={pofAmount}
                                        onChange={(e) => setPofAmount(e.target.value)}
                                        className="border border-gray-300 rounded-md w-full md:w-[200px] lg:w-[300px] text-[12px] py-2 px-4 text-gray-700 leading-tight outline-none"
                                    />
                                </div>
                                <div className="mb-4">
                                    <label
                                        htmlFor="intake-date"
                                        className="block text-gray-700 text-[12px] text-gray-600 mb-2 inter-semilight"
                                    >
                                        Intake Date
                                    </label>
                                    <DatePicker
                                        id="intake-date"
                                        selected={startDate}
                                        onChange={(date) => setStartDate(date)}
                                        className="border border-gray-300 rounded-md w-full md:w-[200px] lg:w-[300px] text-[12px] py-2 px-4 text-gray-700 leading-tight outline-none"
                                        dateFormat="yyyy-MM-dd"
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col md:flex-row justify-between gap-2 pb-2">
                                <div className="mb-4">
                                    <label
                                        htmlFor="banner-image-url"
                                        className="block text-gray-700 text-[12px] text-gray-600 mb-2 inter-semilight"
                                    >
                                        University Type
                                    </label>
                                    <UniversityDropdown
                                        options={['Private', 'Public']}
                                        selectedOption={universityType}
                                        onSelect={UniversityMethod}
                                        toggleDropdown={toggleUniversity}
                                        universityOpen={universityOpen}
                                        setUniversityOpen={setUniversityOpen}
                                    />
                                </div>
                                <div className="mb-4">
                                    <label
                                        htmlFor="minimum-mark"
                                        className="block text-gray-700 text-[12px] text-gray-600 mb-2 inter-semilight"
                                    >
                                        Rank
                                    </label>
                                    <input

                                        type="text"
                                        value={rank}
                                        onChange={(e) => setRank(e.target.value)}
                                        className="border border-gray-300 rounded-md w-full md:w-[200px] lg:w-[300px] text-[12px] py-2 px-4 text-gray-700 leading-tight outline-none"
                                    />
                                </div>
                            </div>


                            <div className='flex flex-col md:flex-row justify-between gap-2 pb-2'>
                                <div className=' mb-4'>
                                    <div>
                                        <label className="block text-gray-700 text-[12px] text-gray-600 mb-2 inter-semilight">Norvel Recommended</label>

                                    </div>

                                    <div className='flex gap-4' >
                                        <label className='flex  gap-2'>
                                            <div>
                                                <input
                                                    type="radio"
                                                    name="norvel_recommended"
                                                    value="true"
                                                    checked={isNorvelRecommended === 'true'}
                                                    onChange={(e) => setIsNorvelRecommended(e.target.value)}
                                                />

                                            </div>

                                            <div className="block text-gray-700 text-[12px] text-gray-600 mt-1  inter-semilight">
                                                Yes
                                            </div>

                                        </label>
                                        <label className='flex gap-2 '>
                                            <div>
                                                <input
                                                    type="radio"
                                                    name="norvel_recommended"
                                                    value="false"
                                                    checked={isNorvelRecommended === 'false'}
                                                    onChange={(e) => setIsNorvelRecommended(e.target.value)}
                                                />

                                            </div>

                                            <div className="block text-gray-700 text-[12px] text-gray-600 mt-1 inter-semilight">
                                                No
                                            </div>

                                        </label>
                                    </div>
                                </div>
                                <div className="mb-4">
                                    <label
                                        htmlFor="campus-locations"
                                        className="block text-gray-700 text-[12px] text-gray-600 mb-2 inter-semilight"
                                    >
                                        University Campus Locations (comma-separated)
                                    </label>
                                    <input
                                        id="campus-locations"
                                        type="text"
                                        value={campusLocations}
                                        onChange={(e) => setCampusLocations(e.target.value)}
                                        className="border border-gray-300 rounded-md w-full md:w-[200px] lg:w-[300px] text-[12px] py-2 px-4 text-gray-700 leading-tight outline-none"
                                        placeholder="Enter locations"
                                    />
                                </div>




                            </div>





                            <div className="flex flex-col pb-2">
                                <label
                                    htmlFor="student-review-urls"
                                    className="block text-gray-700 text-[12px] text-gray-600 mb-2 inter-semilight"
                                >
                                    Student Review URLs
                                </label>
                                {studentReviewUrls.map((urlObj, index) => (
                                    <div key={urlObj.id} className="flex items-center mb-2">
                                        <input
                                            type="text"
                                            value={urlObj.url}
                                            onChange={(e) => {
                                                const newUrls = [...studentReviewUrls];
                                                newUrls[index].url = e.target.value;
                                                setStudentReviewUrls(newUrls);
                                            }}
                                            className="border border-gray-300 rounded-md w-full md:w-[200px] lg:w-[300px] text-[12px] py-2 px-4 text-gray-700 leading-tight outline-none"
                                            placeholder="Enter URL"
                                        />
                                        <button
                                            type="button"
                                            onClick={() => {
                                                setStudentReviewUrls(studentReviewUrls.filter((_, i) => i !== index));
                                            }}
                                            className="text-red-500 ml-2"
                                        >
                                            <DeleteIcon />
                                        </button>
                                    </div>
                                ))}
                                <button
                                    type="button"
                                    onClick={addStudentReviewField}
                                    className="text-black py-2 px-4 rounded-md text-[12px]"
                                >
                                    <AddIcon style={{ fontSize: '16px' }} />
                                </button>
                            </div>
                            <div className="flex flex-col pb-2">
                                <label
                                    htmlFor="university-tour-videos"
                                    className="block text-gray-700 text-[12px] text-gray-600 mb-2 inter-semilight"
                                >
                                    University Tour Videos
                                </label>
                                {universityTourVideos.map((videoObj, index) => (
                                    <div key={videoObj.id} className="flex items-center mb-2">
                                        <input
                                            type="text"
                                            value={videoObj.url}
                                            onChange={(e) => {
                                                const newVideos = [...universityTourVideos];
                                                newVideos[index].url = e.target.value;
                                                setUniversityTourVideos(newVideos);
                                            }}
                                            className="border border-gray-300 rounded-md w-full md:w-[200px] lg:w-[300px] text-[12px] py-2 px-4 text-gray-700 leading-tight outline-none"
                                            placeholder="Enter video URL"
                                        />
                                        <button
                                            type="button"
                                            onClick={() => {
                                                setUniversityTourVideos(universityTourVideos.filter((_, i) => i !== index));
                                            }}
                                            className="text-red-500 ml-2"
                                        >
                                            <DeleteIcon />
                                        </button>
                                    </div>
                                ))}
                                <button
                                    type="button"
                                    onClick={addUniversityTourField}
                                    className=" text-black  py-2 px-4 rounded-md text-[12px]"
                                >
                                    <AddIcon style={{ fontSize: '16px' }} />
                                </button>
                            </div>
                            <div className="flex justify-end  gap-4 pt-8">
                                <button
                                    type="button"
                                    onClick={handleSubmit}
                                    className="bg-black text-white font-bold py-1 px-8 text-[13px] rounded-sm inter-light"
                                >
                                    Update
                                </button>
                                <button
                                    type="button"
                                    onClick={hidden}
                                    className="bg-black text-white font-bold py-1 px-8 text-[13px] rounded-sm inter-light"
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </motion.div>
        </div>
    );
};

export default UpdateUniversity;
